<template>
    <div>
        <v-card flat color="transparent" :width="title_width" :height="title_height" class="pa-4 d-flex align-center">
            <h1 :style="marginStyle" :class="$vuetify.breakpoint.smAndUp ? 'pl-8' : ''" class="bigtitle"
                data-aos-duration="1000" style="line-height: 1;">

                <span data-aos="fade-right" data-aos-delay="500" :style="title_fontSize"
                    v-html="$t('home.experst_in')"></span>

                <ul style="list-style: square;">
                    <li class="text-activity" data-aos="fade-right" data-aos-delay="100">
                        <span :style="activity_fontSize">{{ $t('home.activty01') }}</span>
                    </li>
                    <li class="text-activity" data-aos="fade-right" data-aos-delay="200">
                        <span :style="activity_fontSize">{{ $t('home.activty02') }}</span>
                    </li>
                    <li class="text-activity" data-aos="fade-right" data-aos-delay="300">
                        <span :style="activity_fontSize">{{ $t('home.activty03') }}</span>
                    </li>
                    <li class="text-activity" data-aos="fade-right" data-aos-delay="400">
                        <span :style="activity_fontSize">{{ $t('home.activty04') }}</span>
                    </li>
                    <li class="text-activity" data-aos="fade-right" data-aos-delay="500">
                        <span :style="activity_fontSize">{{ $t('home.activty05') }}</span>
                    </li>
                    <li class="text-activity" data-aos="fade-right" data-aos-delay="600" data-aos-offset="-200">
                        <span :style="activity_fontSize">{{ $t('home.activty06') }}</span>
                    </li>
                    <li class="text-activity" data-aos="fade-right" data-aos-delay="700" data-aos-offset="-400">
                        <span :style="activity_fontSize">{{ $t('home.activty07') }}</span>
                    </li>
                    <li class="text-activity" data-aos="fade-right" data-aos-delay="800" data-aos-offset="-600">
                        <span :style="activity_fontSize">{{ $t('home.activty08') }}</span>
                    </li>
                </ul>

                <div style="height:35px;"></div>


                <i>
                    <span class="pt-8" data-aos="fade-right" data-aos-delay="1200" data-aos-offset="-600"
                        :style="title_fontSize_large" v-html="$t('home.bigsubtitle_3')"></span>
                </i>
                <span class="d-inline-block ml-6" data-aos="fade-right" data-aos-duration="1500" data-aos-offset="-600"
                    data-aos-delay="1500">
                    <v-icon :style="title_image_fontSize_large">mdi-truck-fast-outline</v-icon>
                </span>
                <!-- <span class="d-inline-block ml-6" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="3000">
                    <v-icon :style="title_fontSize_large">mdi-truck-flatbed</v-icon>
                </span> -->
            </h1>
        </v-card>
    </div>
</template>

<script>

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
    once: true
})


export default {
    props: {},
    components: {},
    data: () => ({

    }),
    methods: {},
    mounted() { },
    created() { },
    computed: {
        title_width() {
            if (this.isSmallMobile) {
                return '100vw'
            } else {
                return '100vw'
            }
        },
        title_height() {
            if (this.isSmallMobile) {
                // return '150px'
            } else {
                // return '200px'
            }
        },
        title_fontSize() {
            if (this.isSmallMobile) {
                console.log(1);
                return 'font-size: 24px'
            } else {
                console.log(2);
                return 'font-size: 48px'
            }
        },
        activity_fontSize() {
            if (this.isSmallMobile) {
                console.log(1);
                return 'font-size: 16px'
            } else {
                console.log(2);
                return 'font-size: 24px'
            }
        },
        title_fontSize_large() {
            if (this.isSmallMobile) {
                console.log(1);
                return 'font-size: 36px'
            } else {
                console.log(2);
                return 'font-size: 56px'
            }
        },

        title_image_fontSize_large() {
            if (this.isSmallMobile) {
                console.log(1);
                return 'font-size: 56px'
            } else {
                console.log(2);
                return 'font-size: 120px'
            }
        },
        marginStyle() {
            if (this.isSmallMobile) {
                return 'margin-left:0rem'
            } else {
                return 'margin-left:2vw'
            }
        }
    },
    watch: {}
}
</script>

<style>
.text-activity {
    font-weight: lighter !important;
}

.blue_text {
    color: 1f8ff2
}

.u-red {
    text-decoration: underline;
    -webkit-text-decoration-color: #bd0000ff;
    /* Safari */
    text-decoration-color: #bd0000ff;
    /* text-shadow: 0px 0px 0px #7b7b7b; */
}

.u-blue {
    text-decoration: underline;
    -webkit-text-decoration-color: #1f8ff2;
    /* Safari */
    text-decoration-color: #1f8ff2;
}

.s-red {
    color: #bd0000ff;
    text-shadow: 2px 2px 3px black;
}

.s-blue {
    color: #1f8ff2;
    text-shadow: 2px 2px 3px black;
}
</style>