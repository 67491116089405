<template>
    <div v-if=" !hide">
        <v-card min-width=120 color="transparent" elevation=0
            class="text-center mx-1 pa-3 overable_button border_bottom_fade_left  d-inline-block"
            :class="isDark? 'dark': 'not-dark'" data-aos="fade-in" data-aos-delay="0" :to="{name:'ChiSiamo'}">
            <span class="">
                {{ $t('appbar.btn.who') }}
            </span>
        </v-card>
        <v-card min-width=120 color="transparent" elevation=0 :class="isDark? 'dark': 'not-dark'"
            class="text-center mx-1 pa-3 overable_button border_bottom_fade_left" data-aos="fade-in"
            data-aos-delay="100" :to="{name:'DoveSiamo'}">
            <span class="">
                {{ $t('appbar.btn.where') }}
            </span>
        </v-card>
        <!-- <v-card min-width=120 color="transparent" elevation=0 :class="isDark? 'dark': 'not-dark'"
            class="text-center mx-1 pa-3 overable_button border_bottom_fade_left" data-aos="fade-in"
            data-aos-delay="200" :to="{name:'Clienti'}">
            <span class="">
                {{ $t('appbar.btn.customers') }}
            </span>
        </v-card> -->

        <v-card min-width=120 color="transparent" elevation=0 :class="isDark? 'dark': 'not-dark'"
            class="text-center mx-1 pa-3 overable_button border_bottom_fade_left" data-aos="fade-in"
            data-aos-delay="300" :to="{name:'Blog'}">
            <span class="">
                {{ $t('appbar.btn.theblog') }}
            </span>
        </v-card>

        <v-card min-width=120 color="transparent" elevation=0 :class="isDark? 'dark': 'not-dark'"
            class="text-center mx-1 pa-3 overable_button border_bottom_fade_left" data-aos="fade-in"
            data-aos-delay="400" :to="{name:'ContactUs'}">
            <span class="">
                {{ $t('appbar.btn.contactus') }}
            </span>
        </v-card>

    </div>
</template>

<script>


import AOS from "aos";
import "aos/dist/aos.css";
AOS.init()


export default {
    props: {},
    components: {},
    data: () => ({
        hide: false
    }),
    methods: {},
    mounted() { },
    created() { },
    computed: {},
    watch: {
        style_modalita() {
            this.hide = true
            setTimeout(() => {
                this.hide = false
            }, 50);
        },
        $route(to, from) {
            this.hide = true
            setTimeout(() => {
                this.hide = false
            }, 1);
        }
    }
}
</script>

<style scoped>
.topbutton {
    transform: rotate(-10deg) !important;
    padding-bottom: 2rem;
    text-shadow: -2px -1px 4px #000000a6;
}

/* .overable_button:hover {
    border-bottom: white;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    background: "transparent !important";
} */
/* 
.overable_button:after {
    transform-origin: 0% 50%;
}


.overable_button:after {
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
}


.overable_button::before {
    background-color: transparent !important;
} */

.border_bottom_fade_left {
    display: inline-block;
}

.border_bottom_fade_left:hover {
    background: "transparent !important";
}

.border_bottom_fade_left:hover:after {
    transform: scaleX(1);
}


.border_bottom_fade_left:after {
    display: block;
    content: '';
    background: "transparent !important";
    border-bottom: solid 3px white;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
}


.v-card--link {
    color: white;
}

.v-card--link.router-link-exact-active.not-dark {
    background: white !important;
    /* background: linear-gradient(0deg, rgba(6, 99, 181, 0) 0%, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 1) 100%); */
    color: black !important;
}

.v-card--link.router-link-exact-active.dark {
    background: black !important;
    /* background: linear-gradient(0deg, rgba(6, 99, 181, 0) 0%, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 100%); */
    color: white !important;
}
</style>