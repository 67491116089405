<template>
    <div>
        <!-- <v-navigation-drawer v-model="drawer.status" fixed left temporary :style="backgroundDrawer"> -->
        <v-navigation-drawer v-model="drawer.status" fixed left temporary elevation="12">

            <!-- <div style="background:linear-gradient(180deg, rgba(255,255,255,1) 90%, rgba(6,99,181,0) 100%);width:100%; height: 56px;">
                <v-img class="ml-5" width="130" alt="Logo" src="@/assets/consorzio.png" />
            </div> -->

            <div class="pt-4">
                <v-card :class="isDark ? 'darklink' : 'lightlink'" color="transparent" elevation=0
                    class="mx-1 pa-3 overable_button border_bottom_fade_left" data-aos="fade-in" data-aos-delay="0"
                    :to="{ name: 'ChiSiamo' }">
                    <span class="">
                        {{ $t('appbar.btn.who') }}
                    </span>
                </v-card>
                <v-card :class="isDark ? 'darklink' : 'lightlink'" color="transparent" elevation=0
                    class="mx-1 pa-3 overable_button border_bottom_fade_left" data-aos="fade-in" data-aos-delay="100"
                    :to="{ name: 'DoveSiamo' }">
                    <span class="">
                        {{ $t('appbar.btn.where') }}
                    </span>
                </v-card>
                <!-- <v-card :class="isDark ? 'darklink':'lightlink'" color="transparent" elevation=0
                    class="mx-1 pa-3 overable_button border_bottom_fade_left" data-aos="fade-in" data-aos-delay="200"
                    :to="{name:'Clienti'}">
                    <span class="">
                        {{ $t('appbar.btn.customers') }}
                    </span>
                </v-card> -->

                <v-card :class="isDark ? 'darklink' : 'lightlink'" color="transparent" elevation=0
                    class="mx-1 pa-3 overable_button border_bottom_fade_left" data-aos="fade-in" data-aos-delay="300"
                    :to="{ name: 'Blog' }">
                    <span class="">
                        {{ $t('appbar.btn.theblog') }}
                    </span>
                </v-card>

                <v-card :class="isDark ? 'darklink' : 'lightlink'" color="transparent" elevation=0
                    class="mx-1 pa-3 overable_button border_bottom_fade_left" data-aos="fade-in" data-aos-delay="400"
                    :to="{ name: 'ContactUs' }">
                    <span>
                        {{ $t('appbar.btn.contactus') }}
                    </span>
                </v-card>


                <v-card :class="isDark ? 'darklink' : 'lightlink'" color="transparent" elevation=0
                    class="mx-1 pa-3 overable_button border_bottom_fade_left" data-aos="fade-in" data-aos-delay="400"
                    :to="{ name: 'Admin' }">
                    <span>
                        {{ $t('appbar.btn.reservedArea') }}
                    </span>
                </v-card>


                <!-- <v-btn v-if="isLogged && $vuetify.breakpoint.smAndUp" lass="mx-1" small color="white" icon :to="{ name: 'Admin' }"> -->
                <!-- <v-icon small color="white">mdi-location-exit</v-icon> -->
                <!-- </v-btn> -->



                <!-- <div class="d-flex justify-center mt-12">
                    <img style="max-width:150px" contain class="ml-6 mt-4" alt="Under Construction"
                        src="@/assets/uc01.png" />
                </div> -->
            </div>
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    props: {
        drawer: {
            type: Object
        }
    },
    components: {},
    data: () => ({
    }),
    methods: {},
    mounted() { },
    created() { },
    computed: {
        // backgroundDrawer() {
        //     if (this.isDark) {
        //         return 'background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 3%, rgba(0,0,0,1) 100%);'
        //         // return 'background: blue'
        //     } else {
        //         return 'background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 2%, rgba(255,255,255,1) 100%)'
        //         // return 'background: red'
        //     }
        // }
    },
    watch: {}
}
</script>

<style scoped>
.router-link-exact-active.darklink {
    background: white !important;
    color: black !important;
}

.router-link-exact-active.lightlink {
    background: black !important;
    color: white !important;
}
</style>