export const computed_styling = {
    computed: {
        style_modalita() {
            let mode = this.$store.getters["styling/get_modalita"]
            // console.log(mode);
            if (mode != "dark" && mode != "light") mode = "dark"
            return mode
        },
        title_background() {
            if (this.style_modalita == 'dark') {
                return "background: #000000b3"
            } else {
                return "background: #ffffffe6"
            }
        },
        main_article_background() {
            if (this.style_modalita == 'dark') {
                return '##ffffffcc !important'
                return "background: #000000b3"
            } else {
                // return '#000000c9 !important'
                return "background: #ffffffe6"
            }
        },

        isDark() {
            if (this.style_modalita == 'dark') {
                return true
            } else {
                return false
            }
        },
        isSmallMobile() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return true
                case 'sm':
                    return true
                    // case 'md':
                    //     return "font-size: 1.1rem"
                    // case 'lg':
                    //     return "font-size: 1rem"
                    // case 'xl':
                    //     return "font-size: 1.1rem"
                default:
                    return false
            }
        },

        negative_color_class() {
            if (this.isDark) {
                return 'white'
            } else {
                return 'black'
            }
        },

        positive_color_class() {
            if (this.isDark) {
                return 'black'
            } else {
                return 'white'
            }
        },

        negative_color_style() {
            if (this.isDark) {
                return 'color: white'
            } else {
                return 'color: black'
            }
        },

        card_negative() {
            if (this.isDark) {
                return '#ffffffd1'
            } else {
                return '#000000cf'
            }
        },
        card_positive() {
            if (this.isDark) {
                return '#000000cf'
            } else {
                return '#ffffffd1'
            }
        },
        card_positive_style() {
            if (this.isDark) {
                return 'background: #000000cf !important'
            } else {
                return 'background: #ffffffd1 !important'
            }
        },
        redColor() {
            // return 'transparent'
            return '#bd0000'
        },
        blueColor() {
            // return 'transparent'
            return '#1f8ff2'
        }
    },

}