
<template>
    <!-- <v-app-bar app color="transparent" dark elevation="0"> -->


    <v-app-bar app :style="appbar_background" elevation="0">

        <v-app-bar-nav-icon dense class="mr-3" small :color="isDark ? 'white' : 'black'" v-if="isSmallMobile"
            @click.stop="drawer.status = !drawer.status">
        </v-app-bar-nav-icon>
        <v-card color="transparent" :ripple="false" flat tile :to="{ name: 'ChiSiamo' }">

            <v-img :to="{ name: 'ChiSiamo' }" v-if="!isDark" data-aos="fade-in" data-aos-delay="0" class="" max-width="120"
                alt="Logo" src="../assets/logo/dark120.png" />

            <v-img :to="{ name: 'ChiSiamo' }" v-if="isDark" data-aos="fade-in" data-aos-delay="0" class="" max-width="120"
                alt="Logo" src="../assets/logo/light120.png" />
        </v-card>
        <!-- <div class="d-flex align-center" :to="{name:'Home'}"> -->

        <!-- <v-img alt="Vuetify Logo" class="shrink mr-2" contain 
                src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png" transition="scale-transition"
                width="40"/> -->
        <!-- <v-btn  :to="{name:'TestPage'}" ></v-btn> -->

        <!-- <v-img alt="Vuetify Name" class="shrink mt-1 hidden-sm-and-down" contain min-width="100"
                src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png" width="100" /> -->
        <!-- <v-btn small icon color="primary" :to="{name:'Home'}"> -->
        <!-- <v-btn small icon color="white" :to="{name:'Home'}">
                <v-icon color="white">
                    mdi-home
                </v-icon>
            </v-btn> -->
        <!-- <h1 class="secondary--text">LOGO</h1> -->
        <!-- </div> -->

        <v-spacer></v-spacer>



        <TopBarNavigationButtons v-if="!isSmallMobile" />
        <!-- <v-spacer></v-spacer> -->


        <v-btn v-if="!isLogged && $vuetify.breakpoint.smAndUp" lass="mx-1" small color="white" icon :to="{ name: 'Admin' }">
            <v-icon small color="white">mdi-key</v-icon>
        </v-btn>

        <v-btn v-if="isLogged && $vuetify.breakpoint.smAndUp" lass="mx-1" small color="white" icon :to="{ name: 'Admin' }">
            <v-icon small color="white">mdi-location-exit</v-icon>
        </v-btn>


        <div class="mx-1">
            <ThemeSwitcher />
        </div>
        <div class="mx-1">
            <LanguageSwitcher />
        </div>
        <!-- <v-btn href="https://github.com/vuetifyjs/vuetify/releases/latest" color="primary" target="_blank" text>
            <span class="mr-2 secondary--text">Latest Release</span>
            <v-icon>mdi-open-in-new</v-icon>
        </v-btn> -->
    </v-app-bar>
</template>

<script>
import ThemeSwitcher from '@/components/ThemeSwitcher.vue'
import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
import TopBarNavigationButtons from '@/components/TopBarNavigationButtons'

export default {
    props: {
        drawer: {
            type: Object
        }
    },
    components: {
        ThemeSwitcher,
        TopBarNavigationButtons,
        LanguageSwitcher
    },
    data: () => ({

    }),
    methods: {
        logout() {
            this.$store.dispatch("auth/act_logout")
        }
    },
    mounted() { },
    created() { },
    computed: {


        appbar_background() {
            if (this.isSmallMobile) {
                // return 'background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(6,99,181,0.1) 20%, rgba(6,99,181,0.75) 40%, rgba(6,99,181,1) 100%);'

                // return 'background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(6,99,181,1) 20%, rgba(6,99,181,1) 100%);'
                // return 'background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(6,99,181,1) 8%, rgba(6,99,181,1) 100%);'
                if (!this.isDark) {
                    return 'background: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(6,99,181,0.75) 90%);'
                } else {
                    return 'background: linear-gradient(90deg, rgba(0,0,0,1) 50%, rgba(6,99,181,0.75) 90%);'
                }
                return 'background: white'
                return 'background: #0663b5'
                // return '##f5f5f5e8'
            } else {
                if (!this.isDark) {
                    return 'background: linear-gradient(90deg, rgba(255,255,255,1) 10%, rgba(6,99,181,0.75) 50%);'
                } else {
                    return 'background: linear-gradient(90deg, rgba(0,0,0,1) 50%, rgba(6,99,181,0.75) 90%);'
                }

                // return 'background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(6,99,181,0.1) 20%, rgba(6,99,181,0.75) 40%, rgba(6,99,181,1) 100%);'
                return 'background:  #0663b5'
                return 'red'
                return '#272727f0'
            }
        },
    },
    watch: {}
}
</script>