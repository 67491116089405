export const computed_auth = {
    computed: {
        isLogged() {
            return this.$store.getters["auth/get_isLogged"]
        },
        myUsername() {
            return this.$store.getters["auth/get_username"]
        },
        isAdmin() {
            return this.$store.getters["auth/get_isAdmin"]
        },
        isLocked() {
            return this.$store.getters["auth/get_isLocked"]
        },
        isEditor() {
            return this.$store.getters["auth/get_isEditor"]
        },
        isContactManager() {
            return this.$store.getters["auth/get_isContactManager"]
        },
    },
}