<template>
  <div>
    <ul class="cb-slideshow" :class="style_modalita == 'light' ? 'cb-slideshow-light' : 'cb-slideshow-dark'">
      <div>
        <span>Image 01</span>
        <div></div>
      </div>
      <div>
        <span>Image 02</span>
        <div></div>
      </div>
      <div>
        <span>Image 03</span>
        <div></div>
      </div>
      <div>
        <span>Image 04</span>
        <div></div>
      </div>
      <div>
        <span>Image 05</span>
        <div></div>
      </div>
    </ul>
    <v-app fill-height style="background: none; overflow: hidden;">
      <TopBar :drawer="drawer" />
      <LateralDrawer v-if="isSmallMobile" :drawer="drawer" />
      <v-main>

        <!-- BR {{ $vuetify.breakpoint.name }} -->
        <!-- <v-img class="ml-6 mt-4" max-width="300" alt="Logo" src="@/assets/consorzio.png" /> -->

        <router-view style="margin-bottom:10rem" :key="$route.fullPath" />
        <Footer />

      </v-main>
      <GoogleTagManager />
      <cookiePolicy />
      <dialogCookie />

    </v-app>
  </div>

</template>

<script>
import "@/styles/background_animation.css";
import "@/styles/fonts.css";
import TopBar from '@/components/TopBar.vue'
import LateralDrawer from '@/components/LateralDrawer.vue'
import Footer from '@/components/Footer.vue'
import i18n from '@/plugins/i18n';
import dialogCookie from "@/components/dialogCookie.vue";
import cookiePolicy from "@/components/cookiePolicy.vue";
import GoogleTagManager from "@/components/GoogleTagManager.vue";





export default {
  props: {},
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Consorzio 1980',
    // all titles will be injected into this template
    titleTemplate: '%s | Consorzio 1980'
  },
  components: {
    TopBar,
    LateralDrawer,
    Footer,
    dialogCookie,
    cookiePolicy,
    GoogleTagManager
  },
  data: () => ({
    drawer: {
      status: false,
      darkmode: false

    }
  }),
  methods: {
    handledarkmode() {
      // if (process.browser) {
      if (this.darkmode === true) {
        this.$vuetify.theme.dark = false
        setInterval(() => {
          this.$vuetify.theme.dark = true
        }, 250);
        localStorage.setItem('DarkMode', true)
        this.$store.dispatch("styling/act_set_modalita", 'dark');
      } else if (this.darkmode === false) {
        this.$vuetify.theme.dark = true
        setInterval(() => {
          this.$vuetify.theme.dark = false
        }, 250);
        localStorage.setItem('DarkMode', false)
        this.$store.dispatch("styling/act_set_modalita", 'light');
      }
      // }
    },
  },
  mounted() {
    // Try to load language from stored vuex setting
    const vuex_language = this.$store.getters["language/get_language"]
    if (vuex_language) {
      // console.log('loaded vuex language', vuex_language);
      i18n.locale = vuex_language
    }
    if (localStorage.getItem('DarkMode')) {
      const cookieValue = localStorage.getItem('DarkMode') === 'true'
      this.darkmode = cookieValue
    } else {
      this.handledarkmode()
    }
  },
  created() {
    // if (process.browser) {
    if (localStorage.getItem('DarkMode')) {
      const cookieValue = localStorage.getItem('DarkMode') === 'true'
      this.darkmode = cookieValue
    } else {
      this.handledarkmode()
    }
    // }

  },
  computed: {



  },
  watch: {
    isDark() {
      console.log('switch theme');
    },
    // darkmode(oldval, newval) {
    //   this.handledarkmode()
    // }
  }

};
</script>
<style>
p {
  font-weight: 600;
}
</style>