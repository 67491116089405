<template>
  <div>
    <!-- <v-container fluid fill-height> -->

    <!-- <v-container id="scrolling-techniques-7"> -->
    <!-- <v-img data-aos="fade-in" data-aos-delay="200" class="" max-width="300" alt="Logo"
        src="../assets/consorzio.png" /> -->

    <BigTitle data-aos="fade-right" />
    <BigSubTitle />


    <div style="width:100%" class="pt-12 text-center">
      <v-icon x-large>mdi-arrow-down-thick</v-icon>
    </div>

    <!-- <v-card class="custom-spacer" color="transparent" flat :height="spacerUnderTitle"></v-card> -->

    <!-- data-aos="fade-in" :data-aos-duration="aosDuration" -->

    <OurTarget :style="positionBoxStyle" firtsBox :topWaveColor="redColor" :bottomWaveColor="redColor" boxStyle="2" />
    <!-- <v-card class="custom-spacer" color="transparent" flat :height="spacerUnderbox"></v-card> -->

    <!-- data-aos="fade-in" :data-aos-duration="aosDuration"
      :data-aos-offset="aosOffset" -->

    <OurValues :style="positionBoxStyle" :topWaveColor="redColor" :bottomWaveColor="blueColor" boxStyle="1" />
    <!-- <v-card class="custom-spacer" color="transparent" flat :height="spacerUnderbox"></v-card> -->

    <!-- data-aos="fade-in" :data-aos-duration="aosDuration" :data-aos-offset="aosOffset" -->

    <OurTeam :style="positionBoxStyle" :topWaveColor="blueColor" :bottomWaveColor="redColor" boxStyle="2" />
    <!-- <v-card class="custom-spacer" color="transparent" flat :height="spacerUnderbox"></v-card> -->

    <!-- data-aos="fade-in" :data-aos-duration="aosDuration"
      :data-aos-offset="aosOffset" -->

    <GreenEnergy :style="positionBoxStyle" :topWaveColor="redColor" :bottomWaveColor="blueColor" boxStyle="1" />
    <!--  -->

    <!-- <GoToContactUs />  -->

    <!-- data-aos="fade-in" :data-aos-duration="aosDuration"
      :data-aos-offset="aosOffset" -->

    <BlockContactUs :style="positionBoxStyle" lastBox :topWaveColor="blueColor" :bottomWaveColor="blueColor"
      boxStyle="2" />

    <!--  -->
    <v-card class="custom-spacer" color="transparent" flat :height="spacerUnderbox"></v-card>

    <LoadArticoli :box_title="$t('loadarticoli.title')" />

  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import LoadArticoli from '@/components/LoadArticoli.vue'

import BigTitle from '@/components/pages/BigTitle.vue'
import BigSubTitle from '@/components/pages/BigSubTitle.vue'
import OurTarget from '@/components/pages/OurTarget.vue'
import OurValues from '@/components/pages/OurValues.vue'
import OurTeam from '@/components/pages/OurTeam.vue'
import GreenEnergy from '@/components/pages/GreenEnergy.vue'
import GoToContactUs from '@/components/pages/GoToContactUs.vue'
import BlockContactUs from '@/components/pages/BlockContactUs.vue'



import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
   once: true
})

window.addEventListener('load', function () {
  AOS.refresh();
});

export default {
  props: {},
  components: {
    HelloWorld,
    LoadArticoli,
    BigTitle,
    BigSubTitle,
    OurTarget,
    OurValues,
    OurTeam,
    GreenEnergy,
    GoToContactUs,
    BlockContactUs
  },
  data: () => ({
    aosDuration: 750
  }),
  methods: {},
  mounted() { },
  created() { },
  computed: {

    positionBoxStyle() {
      if (this.isSmallMobile) {
        return 'position:relative; top:-20px;'
      } else {
        return 'position:relative; top:-120px;'
      }
    },

    aosOffset() {
      if (this.isSmallMobile) {
        return 100
      } else {
        return 200
      }

    },
    spacerUnderTitle() {
      if (this.isSmallMobile) {
        return 'height:100px !important'
      } else {
        return 'height:0px !important'
      }
    },
    spacerUnderbox() {
      if (this.isSmallMobile) {
        return 20
      } else {
        return 200
      }
    }
  },
  watch: {},

}
</script>

<style scoped>

</style>