<template>
    <v-card v-if="hide == false && articoli.length > 0" flat elevation="elevation" :color="backgroundColorCard"
        :style="background_style" class="py-6" style="overflow:hidden;">
        <v-row justify="center">
            <v-col cols="11" sm="11" md="10" lg="9" xl="6">
                <!-- <h1>{{ $t('loadarticoli.title') }}</h1> -->
                <!-- <h1>{{ box_title }}</h1> -->
                <v-row>
                    <v-col cols="12" sm=6 md=4 v-for="(articolo, index) in articoli" :key="articolo.id">
                        <v-card :to="{ name: 'ReadFullArticle', params: { id_articolo: articolo.id } }"
                            data-aos="flip-left" :data-aos-delay="aos_delay(index)" :color="backgroundColor">
                            <v-img height="350" :src="default_image(articolo)" :lazy-src="default_image(articolo)"
                                class="secondary--text align-end">
                                <div :style="title_background" class="pr-2 text-right">
                                    <span class="body-2" :style="negative_color_style">
                                        {{ articolo_data(articolo) }}
                                    </span>
                                </div>

                                <Titolo :style="negative_color_style" :titolo="locale_titolo(articolo)" />

                                <div class="text-center pt-2" :style="title_background">
                                    <v-btn :color="negative_color_class" block text outlined
                                        :to="{ name: 'ReadFullArticle', params: { id_articolo: articolo.id } }">
                                        <span :style="negative_color_style">
                                            {{ $t('loadarticoli.readfullarticle') }}
                                        </span>
                                    </v-btn>
                                </div>
                            </v-img>
                        </v-card>
                    </v-col>
                </v-row>

            </v-col>
        </v-row>


        <v-row>
            <v-col cols=12 class="text-center">
                <a v-if="more_available_articoli" class="secondary--text" @click="load_more()">
                    <strong :style="negative_color_style">
                        {{ $t('loadarticoli.loadmore') }}
                    </strong>
                </a>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import i18n from '../i18n'
import Titolo from '@/components/articoli/Titolo.vue'
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init()

export default {
    props: {
        skip_id: String,
        box_title: String,
        backgroundColor: String,
        elevation: {
            type: Number,
            default: 6
        }
    },
    components: {
        Titolo
    },
    data: () => ({
        hide: false
    }),
    methods: {
        load_last3() {
            this.$store.dispatch("articoli/act_get_article_batch_from_latest")
        },
        locale_titolo(articolo) {
            return articolo.title[this.current_locale]
        },
        aos_delay(index) {
            return 100 * index
        },



        default_image(articolo) {
            const no_img_url = 'https://firebasestorage.googleapis.com/v0/b/consorzio1980-web.appspot.com/o/no_image.jpeg?alt=media&token=11012f93-cab3-405d-a061-32fbf3a2dd91'
            // const no_img_url = null
            // console.log(articolo.images);
            let default_img_url
            if (articolo && articolo.images) {
                articolo.images.forEach(image => {
                    if (image.default) {
                        default_img_url = image.url
                    }
                });
            }
            console.log('default_img_url', default_img_url);

            if (default_img_url != undefined) {
                return default_img_url
            } else {
                console.log('no img');
                return no_img_url
            }
        },

        // default_image(articolo) {
        //     const no_img_url = 'https://firebasestorage.googleapis.com/v0/b/consorzio1980-web.appspot.com/o/no_image.jpeg?alt=media&token=7c76f5fe-df14-4d0d-840d-946282bad8e5'
        //     // const no_img_url = null
        //     // console.log(articolo.images);
        //     let default_img_url
        //     if (articolo.images) {
        //         articolo.images.forEach(image => {
        //             if (image.default) {
        //                 default_img_url = image.url
        //             }
        //         });
        //         // const img_url = articolo.images.filter(image => {
        //         //     if (image.default) return true
        //         // })[0].url
        //         console.log('default_img_url', default_img_url);
        //         return default_img_url
        //     } else {
        //         return no_img_url
        //     }
        // },
        articolo_data(articolo) {
            // console.log(articolo.creation);
            // return articolo.creation.toLocaleDateString('it-it', { year: 'numeric', month: '2-digit', day: '2-digit' })
            if (articolo.creation) {
                return new Date(articolo.creation*1000).toLocaleDateString('it-it', { year: 'numeric', month: '2-digit', day: '2-digit' })

                // return articolo.creation.toDate().toLocaleDateString('it-it', { year: 'numeric', month: '2-digit', day: '2-digit' })
            }
        },
        load_more() {
            // console.log('M load_more');
            this.load_last3()
        }
    },
    mounted() {
        console.log('backgroundColor', this.backgroundColor);
        this.load_last3()
    },
    created() { },
    computed: {
        background_style() {
            if (!this.backgroundColor) {
                return "background: linear-gradient(180deg, rgba(255,255,255,0) 20%, rgba(6,99,181,1) 50%,rgba(255,255,255,0) 100%)"
            }
        },
        // current_locale() {
        //     return this.$store.getters["language/get_language"]
        // },
        articoli() {

            // return this.$store.getters["articoli/get_articoli"]
            let articoli = this.$store.getters["articoli/get_articoli"]
            articoli = articoli.filter(articolo => {
                if (articolo.id == this.skip_id) {
                    return false
                } else {
                    return true
                }
            })
            return articoli
        },
        more_available_articoli() {
            return this.$store.getters["articoli/get_more_available_articoli"]
        },

        backgroundColorCard() {
            if (this.backgroundColor) {
                console.log(this.backgroundColor);
                return "background-color: " + this.backgroundColor
            } else {
                return "background-color: transparent"
            }
        },

        title_size() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return "font-size: 1rem"
                case 'sm':
                    return "font-size: 1.2rem"
                case 'md':
                    return "font-size: 1.1rem"
                case 'lg':
                    return "font-size: 1rem"
                case 'xl':
                    return "font-size: 1.1rem"
                default:
                    return "font-size: 1.4rem"
                    break;
            }
        },


    },
    watch: {
        style_modalita() {
            this.hide = true
            setTimeout(() => {
                this.hide = false
            }, 50);
        },
    }
}
</script>

<style>

</style>