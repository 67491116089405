<template>

</template>

<script>

import { gtag } from "@/plugins/gtag";


function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export default {
    props: {},
    components: {},
    data: () => ({
        cookies_decision: null,
    }),
    methods: {
        init() {
            gtag("consent", "default", {
                ad_storage: "denied",
                analytics_storage: "denied",
                wait_for_update: 2500,
            });
            gtag("set", "url_passthrough", true);
            gtag("set", "ads_data_redaction", true);

            setTimeout(() => {
                const lp_cookies_decision = getCookie("lp_cookies_decision");
                const analytics_storage = getCookie("analytics_storage");
                const ad_storage = getCookie("ad_storage");
                console.log("lp_cookies_decision", lp_cookies_decision);
                console.log("analytics_storage", analytics_storage);
                console.log("ad_storage", ad_storage);
                if (!lp_cookies_decision) {
                    this.cookie_dialog = true;
                    console.log("SHOW COOKIE DIALOG");
                } else {
                    if (analytics_storage == "granted") {
                        this.consent_mode_grant_analytics();
                    } else {
                        this.consent_mode_revoke_analytics();
                    }
                    if (ad_storage == "granted") {
                        this.consent_mode_grant_google_ads();
                    } else {
                        this.consent_mode_revoke_google_ads();
                    }
                }
            }, 100);

            this.cookies_decision = getCookie("lp_cookies_decision");

            // console.log(this.cookies_decision);
            // setInterval(() => {
            //   this.showCookieBanner = true;
            // }, 1800);
            this.status_audio = localStorage.getItem("audio");
            // console.log("localStorage get: " + this.status_audio);
            if (this.status_audio != 0 && this.status_audio != 1) {
                this.status_audio = 1;
            }
        },
        consent_mode_grant_analytics() {
            console.log("consent_mode accept analytics");
            gtag("consent", "update", {
                analytics_storage: "granted",
            });
        },
        consent_mode_revoke_analytics() {
            console.log("consent_mode revoke analytics");
            gtag("consent", "update", {
                analytics_storage: "denied",
            });
        },
        consent_mode_grant_google_ads() {
            console.log("consent_mode accept google_ads");
            gtag("consent", "update", {
                ad_storage: "granted",
            });
        },
        consent_mode_revoke_google_ads() {
            console.log("consent_mode revoke google_ads");
            gtag("consent", "update", {
                ad_storage: "denied",
            });
        },
    },
    mounted() {
        console.log('GoogleTagManager');
        this.init()
     },
    created() { },
    computed: {},
    watch: {}
}
</script>

<style>

</style>