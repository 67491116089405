<template>
  <div>
    <v-bottom-sheet v-model="cookie_dialog" max-width="800" persistent>
      <v-card dark tile color="black pa-8" style="
          border-color: white !important;
          border-style: solid;
          border-top-style: solid;
          border-top-color: white !important;
          border-bottom-style: solid;
          border-bottom-color: black !important;
        ">
        <v-card-text class="pt-4 pb-0" style="font-size: 0.9rem">
          <div class="cookie_message">
            <h3>{{ $t('cookieDialog.h3.1') }}</h3>
            <span>
              {{ $t('cookieDialog.text1') }}

            </span>
            <span>
              {{ $t('cookieDialog.text2') }}
            </span>
            <span>
              {{ $t('cookieDialog.text2') }}


            </span>

            <p>
              {{ $t('cookieDialog.text4') }}
              <strong class="white--text">
                <a style="color: white !important" @click="view_cookie_policy()">cookie policy</a>
              </strong>
              .
            </p>
          </div>

          <div v-if="cookie_settings">
            {{ $t('cookieDialog.text5') }}
            <v-checkbox hide-details class="pt-0 mt-2 pb-2" v-model="cookies.analytics">
              <template v-slot:label>
                <span style="font-size: 0.95rem; color: white">
                  Google Analytics
                </span>
              </template>
            </v-checkbox>
            <!-- <v-checkbox hide-details class="pt-0 mt-2 pb-2" v-model="cookies.ads">
              <template v-slot:label>
                <span style="font-size: 0.95rem; color: white">Google ADS</span>
              </template>
            </v-checkbox> -->
            <!-- <v-checkbox hide-details class="pt-0 mt-2 pb-2" v-model="cookies.linkedin_insight">
              <template v-slot:label>
                <span style="font-size: 0.95rem; color: white">Linkedin Insight</span>
              </template>
            </v-checkbox> -->
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="white" min-width="" text @click="cookie_settings = !cookie_settings" outlined>
            {{ $t('cookieDialog.btn.customize') }}
          </v-btn>
          <v-spacer />
          <v-btn color="white" min-width="100" @click="saveCookiePermisison()">
            <span class="black--text"> {{ $t('cookieDialog.btn.save') }} </span>
          </v-btn>
          <!-- <v-btn color="white" min-width="100" @click="cookie_dialog = false">
            <span class="black--text"> chiudi </span>
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { gtag } from "@/plugins/gtag";
import { EventBus } from "@/main";

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(c_name, value, expiredays) {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  // console.log(
  //   c_name +
  //     "=" +
  //     escape(value) +
  //     (expiredays == null ? "" : ";expires=" + exdate)
  // );
  document.cookie =
    c_name +
    "=" +
    escape(value) +
    (expiredays == null ? "" : ";expires=" + exdate);
}

// ----------------------------------

function get_cookie(name) {
  return document.cookie.split(";").some((c) => {
    return c.trim().startsWith(name + "=");
  });
}

function delete_cookie(name, path, domain) {
  if (get_cookie(name)) {
    document.cookie =
      name +
      "=" +
      (path ? ";path=" + path : "") +
      (domain ? ";domain=" + domain : "") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

export default {
  props: {},
  components: {},
  data: () => ({
    cookie_dialog: false,
    cookies: {
      analytics: true,
      ads: true,
      linkedin_insight: true,
    },

    cookies_was: {
      analytics: null,
      ads: null,
      linkedin_insight: null,
    },
    //
    cookie_settings: false,
  }),
  methods: {
    view_cookie_policy() {
      EventBus.$emit("open_cookie_policy");
    },
    saveCookiePermisison() {
      this.setCookieAcceptance("analytics_storage", this.cookies.analytics);
      this.setCookieAcceptance("ad_storage", this.cookies.ads);
      this.setCookieAcceptance(
        "linkedin_insight",
        this.cookies.linkedin_insight
      );

      this.setCookieAcceptance("lp_cookies_decision", "answered");
      this.cookie_dialog = false;

      if (this.cookies.analytics) {
        this.consent_mode_grant_analytics();
        this.cookies_was.analytics = true;
      } else {
        this.consent_mode_revoke_analytics();
        this.cookies_was.analytics = false;
      }

      if (this.cookies.ads) {
        this.consent_mode_grant_ads();
        this.cookies_was.ads = true;
      } else {
        this.consent_mode_revoke_ads();
        this.cookies_was.ads = false;
      }

      if (this.cookies.linkedin_insight) {
        // console.log("accepted linkedin insight");
        this.cookies_was.linkedin_insight = true;
        dataLayer.push({ event: "ck_accept_linkedin" });
      }

      if (
        this.cookies_was.analytics == true &&
        this.cookies.analytics == false
      ) {
        // console.log("decline analytics,reload");
        location.reload();
        //  Reload the page for unload now unwanted linkedin insight tag
      }

      if (
        this.cookies_was.linkedin_insight == true &&
        this.cookies.linkedin_insight == false
      ) {
        // console.log("decline linkedin insight,reload");
        location.reload();
        //  Reload the page for unload now unwanted linkedin insight tag
      }
    },
    setCookieAcceptance(cookie_type, value) {
      if (value == true) {
        setCookie(cookie_type, "granted");
      } else if (value == false) {
        setCookie(cookie_type, "denied");
      } else {
        setCookie(cookie_type, value);
      }
    },
    consent_mode_grant_analytics() {
      console.log("send gtag consent analytics");
      gtag("consent", "update", {
        analytics_storage: "granted",
      });
    },
    consent_mode_revoke_analytics() {
      gtag("consent", "update", {
        analytics_storage: "denied",
      });
      setTimeout(() => {
        delete_cookie("_ga", "/", "localhost");
        // delete_cookie("_ga", "/", ".avmsquare.com");
        delete_cookie("_ga_FZ7975ZPX2", "/", "localhost");
        // delete_cookie("_ga_FZ7975ZPX2", "/", ".avmsquare.com");
      }, 1000);
    },

    consent_mode_grant_ads() {
      console.log("send gtag consent ads");
      gtag("consent", "update", {
        ad_storage: "granted",
      });
    },
    consent_mode_revoke_ads() {
      // console.log("consent_mode revoke analytics");
      gtag("consent", "update", {
        ad_storage: "denied",
      });
    },
  },
  mounted() {
    if (getCookie("linkedin_insight") == "denied") {
      this.cookies_was.linkedin_insight = false;
      this.cookies.linkedin_insight = false;
    } else {
      this.cookies_was.linkedin_insight = true;
      this.cookies.linkedin_insight = true;
      dataLayer.push({ event: "ck_accept_linkedin" });
    }

    if (getCookie("analytics_storage") == "denied") {
      this.cookies_was.analytics = false;
      this.cookies.analytics = false;
    } else {
      this.cookies_was.analytics = true;
      this.cookies.analytics = true;
    }

    if (getCookie("ad_storage") == "denied") {
      this.cookies_was.ads = false;
      this.cookies.ads = false;
    } else {
      this.cookies_was.ads = true;
      this.cookies.ads = true;
    }

    EventBus.$on("open_cookie_setting", () => {
      console.log("open cookie settings");
      this.cookie_dialog = true;
    });

    // gtag("consent", "default", {
    //   ad_storage: "denied",
    //   analytics_storage: "denied",
    //   // wait_for_update: 500,
    // });
    gtag("set", "url_passthrough", true);
    gtag("set", "ads_data_redaction", true);

    setTimeout(() => {
      const lp_cookies_decision = getCookie("lp_cookies_decision");
      const analytics_storage = getCookie("analytics_storage");
      // const linkedin_insight = getCookie("linkedin_insight");
      console.log("lp_cookies_decision", lp_cookies_decision);
      console.log("analytics_storage", analytics_storage);
      if (!lp_cookies_decision) {
        this.cookie_dialog = true;
        // console.log("SHOW COOKIE DIALOG");
      } else {
        if (analytics_storage == "granted") {
          this.consent_mode_grant_analytics();
        } else {
          this.consent_mode_revoke_analytics();
        }
        // if (linkedin_insight == "granted") {
        //   dataLayer.push({ event: "ck_accept_linkedin" });
        // }
      }
    }, 1000);
  },
  created() { },
  computed: {},
  watch: {},
};
</script>

<style>

</style>