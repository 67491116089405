<template>
    <div>
        <!-- :color="card_positive" -->
        <v-card tile flat :color="cardColor" class="py-8">
            <v-card-text class="text-center" :style="textColor">

                <h3 class="mb-8" :style="title_size">
                    {{ $t('home.contactus.title') }}
                </h3>


                <div class="mt-6 d-flex justify-center align-center flex-column">
                    <v-card height=400 width="400" class="d-flex justify-center align-center flex-column"
                        :to="{ name: 'ContactUs' }">

                        <div class="d-flex justify-center">
                            <v-img data-aos="fade-in" class="" :max-width="image_size" alt="Logo"
                                src="@/assets/imgs/contact_02.png" />
                        </div>

                        <h3 class="mt-8" :style="title_size">
                            {{ $t('home.contactus.btn') }}
                        </h3>
                    </v-card>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => ({

    }),
    methods: {},
    mounted() { },
    created() { },
    computed: {
        image_size() {
            if (this.isSmallMobile) {
                return 200
            } else {
                return 300
            }
        },
        cardColor() {
            // if (this.boxStyle == '1') {
            return this.card_positive
            // } else {
            // return this.card_negative
            // }
        },
        textColor() {
            if (this.isDark) {
                return 'color: #ffffff'
            } else {
                return 'color: #0e0e0e'
            }
        },
        title_size() {
            if (this.isSmallMobile) {
                return 'font-size: 2.4rem; line-height:1;'
            } else {
                return 'font-size: 2.4rem; line-height:1;'
            }
        }
    },
    watch: {}
}
</script>

<style>

</style>