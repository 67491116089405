<template>
    <div>
        <v-btn v-if="current_locale!='it'" icon small @click="changeLocale('it')">
            <flag iso="it" v-bind:squared="false" />
        </v-btn>
        <v-btn v-if="current_locale !='en'" icon small @click="changeLocale('en')">
            <flag iso="gb" v-bind:squared="false" />
        </v-btn>
    </div>



</template>

<script>
import i18n from '@/plugins/i18n';

export default {
    props: {},
    components: {},
    data: () => ({

    }),
    methods: {
        changeLocale(locale) {
            i18n.locale = locale
            this.$store.dispatch("language/act_set_language", locale);
        }
    },
    mounted() { },
    created() { },
    computed: {
    },
    watch: {}
}
</script>

<style>

</style>