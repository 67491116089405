<template>
    <div>

        <svg v-if="firtsBox" width="100%" height="100%" id="svg" viewBox="0 0 1440 500"
            xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"
            style="display:block;">
            <path
                d="M 0,500 C 0,500 0,250 0,250 C 116,244.78468899521533 232,239.56937799043064 318,235 C 404,230.43062200956936 460,226.50717703349278 544,248 C 628,269.4928229665072 740.0000000000001,316.4019138755981 834,317 C 927.9999999999999,317.5980861244019 1004,271.88516746411483 1102,253 C 1200,234.11483253588517 1320,242.05741626794259 1440,250 C 1440,250 1440,500 1440,500 Z"
                stroke="none" stroke-width="0" :fill="cardColor" fill-opacity="1"
                class="transition-all duration-300 ease-in-out delay-150 path-0"></path>
        </svg>


        <v-card flat tile style="" :color="cardColor">
            <svg v-if="!firtsBox" width="100%" height="100%" id="svg" viewBox="0 0 1440 400"
                xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150">
                <path
                    d="M 0,400 C 0,400 0,200 0,200 C 67.19617224880383,188.86124401913878 134.39234449760767,177.72248803827753 242,193 C 349.60765550239233,208.27751196172247 497.62679425837325,249.9712918660287 596,254 C 694.3732057416267,258.0287081339713 743.1004784688996,224.3923444976076 843,228 C 942.8995215311004,231.6076555023924 1093.9712918660287,272.4593301435407 1202,274 C 1310.0287081339713,275.5406698564593 1375.0143540669856,237.77033492822966 1440,200 C 1440,200 1440,400 1440,400 Z"
                    stroke="none" stroke-width="0" :fill="topWaveColor" fill-opacity="1"
                    class="transition-all duration-300 ease-in-out delay-150 path-0" transform="rotate(-180 720 200)">
                </path>
            </svg>

            <div class="text-center pt-4 pb-8" :style="textColor">
                <div class="d-flex justify-center">
                    <v-img data-aos="fade-in" data-aos-offset="600" class="" :max-width="image_size" alt="Logo"
                        src="@/assets/imgs/teams_01.png" />
                </div>
                <h2 data-aos="fade-right" data-aos-anchor-placement="top-bottom" data-aos-offset="400" :style="title_size">
                    {{ $t('home.ourteam.title') }}
                </h2>

                <h3 data-aos="fade-left" data-aos-anchor-placement="top-bottom" data-aos-offset="400" class=" pb-4 box-subtitle underlined">
                    {{ $t('home.ourteam.subtitle') }}
                </h3>



                <div  data-aos="fade-in" data-aos-anchor-placement="bottom-bottom" class="d-flex flex-column justify-center align-center px-6 ">
                    <p class=" limit-p-width">
                        {{ $t('home.ourteam.desc_1') }}
                    </p>
                    <CounterDipendenti class="mb-6" style="max-width:600px; " />

                    <p class=" limit-p-width">
                        {{ $t('home.ourteam.desc_2') }}
                    </p>

                    <p class=" limit-p-width">
                        {{ $t('home.ourteam.desc_3') }}
                    </p>
                    <p class="limit-p-width">
                        {{ $t('home.ourteam.desc_4') }}
                    </p>

                </div>
            </div>


            <svg v-if="!lastBox" width="100%" height="100%" id="svg" style="display: block" viewBox="0 0 1440 400"
                xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150">

                <path
                    d="M 0,400 C 0,400 0,200 0,200 C 88.21052631578945,231.78947368421052 176.4210526315789,263.57894736842104 283,242 C 389.5789473684211,220.42105263157893 514.5263157894736,145.4736842105263 617,146 C 719.4736842105264,146.5263157894737 799.4736842105264,222.52631578947364 878,258 C 956.5263157894736,293.47368421052636 1033.578947368421,288.42105263157896 1127,272 C 1220.421052631579,255.57894736842104 1330.2105263157896,227.78947368421052 1440,200 C 1440,200 1440,400 1440,400 Z"
                    stroke="none" stroke-width="0" :fill="bottomWaveColor" fill-opacity="1"
                    class="transition-all duration-300 ease-in-out delay-150 path-0"></path>

            </svg>

        </v-card>

        <svg v-if="lastBox" width="100%" height="100%" id="svg" viewBox="0 0 1440 400"
            xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150">
            <path
                d="M 0,400 C 0,400 0,200 0,200 C 113.81818181818178,200.48803827751198 227.63636363636357,200.97607655502392 326,200 C 424.36363636363643,199.02392344497608 507.27272727272737,196.58373205741628 599,182 C 690.7272727272726,167.41626794258372 791.2727272727271,140.68899521531102 886,140 C 980.7272727272729,139.31100478468898 1069.6363636363637,164.6602870813397 1161,179 C 1252.3636363636363,193.3397129186603 1346.181818181818,196.66985645933016 1440,200 C 1440,200 1440,400 1440,400 Z"
                stroke="none" stroke-width="0" :fill="cardColor" fill-opacity="1"
                class="transition-all duration-300 ease-in-out delay-150 path-0" transform="rotate(-180 720 200)">
            </path>
        </svg>

    </div>
</template>

<script>
import CounterDipendenti from '@/components/CounterDipendenti.vue'


export default {
    props: {
        topWaveColor: {
            type: String,
            default: 'green'
        },
        bottomWaveColor: {
            type: String,
            default: 'purple'
        },
        boxStyle: {
            type: String,
            default: '1'
        },
        firtsBox: {
            type: Boolean,
            default: false
        },
        lastBox: {
            type: Boolean,
            default: false
        }
    },
    components: {
        CounterDipendenti
    },
    data: () => ({

    }),
    methods: {},
    mounted() { },
    created() { },
    computed: {
        cardColor() {
            if (this.boxStyle == '1') {
                return this.card_positive
            } else {
                return this.card_negative
            }
        },
        textColor() {
            if (this.isDark) {
                if (this.boxStyle == '1') {
                    return 'color: #ffffff'
                } else {
                    return 'color: #0e0e0e'
                }
            } else {
                if (this.boxStyle == '1') {
                    return 'color: #0e0e0e'
                } else {
                    return 'color: #ffffff'
                }
            }
        },
        image_size() {
            if (this.isSmallMobile) {
                return 200
            } else {
                return 300
            }
        },
        title_size() {
            if (this.isSmallMobile) {
                return 'font-size: 2.4rem'
            } else {
                return 'font-size: 3.2rem'
            }
        }
    },
    watch: {}
}
</script>

<style scoped>
/* .box-title {
    font-size: 2.4rem;
} */

.box-subtitle {
    font-size: 1.4rem;
}

.underlined {
    text-decoration: underline;
}

.limit-p-width {
    max-width: 540px;
}
</style>