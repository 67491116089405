import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        defaultTheme: 'dark',
        themes: {
            light: {
                primary: '#ffffff',
                secondary: '#202020',
                logo_primary:"#1f8ff2",
                logo_secondary: "#bd0000ff",
                bg_alt: "#000000d6",
                accent: '#8c9eff',
                error: '#b71c1c',
            },
            dark: {
                primary: '#0e0e0e',
                secondary: '#f8f8f8',
                logo_primary:"#1f8ff2",
                logo_secondary: "#bd0000ff",
                bg_alt: "#000000c4",
                accent: '#cb32cb',
                error: '#cc0000',
            },
        },
    },
});
