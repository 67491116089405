<template>
    <div class="pt-4">
        <v-card flat color="transparent" :width="title_width" :height="title_height" data-aos="fade-right"
            class="pa-4 d-flex flex-column align-center text-left">
            <h1 :style="marginStyle" :class="$vuetify.breakpoint.smAndUp ? 'pl-8' : ''" class="bigtitle" data-aos-duration="3000"
                style="line-height: 1.1;">
                <span :style="title_fontSize" v-html="$t('home.bigtitle')"></span>
            </h1>
        </v-card>
    </div>
</template>

<script>


export default {
    props: {},
    components: {},
    data: () => ({

    }),
    methods: {},
    mounted() { },
    created() { },
    computed: {
        title_width() {
            if (this.isSmallMobile) {
                return '100%'
            } else {
                return '50%'
            }
        },
        title_height() {
            if (this.isSmallMobile) {
                return '300px'
            } else {
                return '350px'
            }
        },
        title_fontSize() {
            if (this.isSmallMobile) {
                return 'font-size: 48px'
            } else {
                return 'font-size: 64px'
            }
        },
        marginStyle() {
            if (this.isSmallMobile) {
                return 'margin-left:0rem'
            } else {
                return 'margin-left:2vw'
            }
        }
    },
    watch: {}
}
</script>

<style>
.bigtitle {
    transform: rotate(0deg) !important;
    /* font-size: 3rem; */
    padding-bottom: 2rem;
    /* text-shadow: -2px -1px 4px #000000a6; */
    text-shadow: -1px -1px 3px #7b7b7b;
}

.blue_text {
    color: #1f8ff2
}

.u-red {
    text-decoration: underline;
    -webkit-text-decoration-color: #bd0000ff;
    /* Safari */
    text-decoration-color: #bd0000ff;
    /* text-shadow: 0px 0px 0px #7b7b7b; */
}

.u-blue {
    text-decoration: underline;
    -webkit-text-decoration-color: #1f8ff2;
    /* Safari */
    text-decoration-color: #1f8ff2;
}
</style>