import { VApp } from 'vuetify/lib/components/VApp';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',{staticClass:"cb-slideshow",class:_vm.style_modalita == 'light' ? 'cb-slideshow-light' : 'cb-slideshow-dark'},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)]),_c(VApp,{staticStyle:{"background":"none","overflow":"hidden"},attrs:{"fill-height":""}},[_c('TopBar',{attrs:{"drawer":_vm.drawer}}),(_vm.isSmallMobile)?_c('LateralDrawer',{attrs:{"drawer":_vm.drawer}}):_vm._e(),_c(VMain,[_c('router-view',{key:_vm.$route.fullPath,staticStyle:{"margin-bottom":"10rem"}}),_c('Footer')],1),_c('GoogleTagManager'),_c('cookiePolicy'),_c('dialogCookie')],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("Image 01")]),_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("Image 02")]),_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("Image 03")]),_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("Image 04")]),_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("Image 05")]),_c('div')])
}]

export { render, staticRenderFns }