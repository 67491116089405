import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },


  {
    path: '/contacts',
    name: 'ContactUs',
    component: () => {
      return import( /* webpackChunkName: "ContactUs" */ '@/views/ContactUs.vue')
    },
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => {
      return import( /* webpackChunkName: "Blog" */ '@/views/Blog.vue')
    },
  },
  {
    path: '/401',
    name: 'Unauthorized',
    component: () => {
      return import( /* webpackChunkName: "AdminPanel" */ '@/views/admin/401.vue')
    },
  },
  {
    path: '/locked',
    name: 'Locked',
    component: () => {
      return import( /* webpackChunkName: "Locked" */ '@/views/admin/Locked.vue')
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => {
      return import( /* webpackChunkName: "AdminPanel" */ '@/views/admin/AdminPanel.vue')
    },
  },

  {
    path: '/admin/password_reset',
    name: 'PasswordReset',
    component: () => {
      return import( /* webpackChunkName: "PasswordReset" */ '@/views/admin/PasswordReset.vue')
    },
  },

  {
    path: '/admin/password_change',
    name: 'PasswordChange',
    component: () => {
      return import( /* webpackChunkName: "PasswordChange" */ '@/views/admin/PasswordChange.vue')
    },
    meta: {
      requiresAuth: true,
    },
  },
  // ---------------------------
  // Authenticated views
  {
    path: '/admin/contacts',
    name: 'ListContacts',
    component: () => {
      return import( /* webpackChunkName: "ViewContacts" */ '@/views/admin/ListContacts.vue')
    },
    meta: {
      requiresContactsManagerRole: true,
    },
  },
  {
    path: '/admin/accounts',
    name: 'ListAccounts',
    component: () => {
      return import( /* webpackChunkName: "ListAccounts" */ '@/views/admin/ListAccounts.vue')
    },
    meta: {
      requiresAdminRole: true,
    },
  },
  {
    path: '/admin/posts',
    name: 'ListPosts',
    component: () => {
      return import( /* webpackChunkName: "ListPosts" */ '@/views/admin/ListPosts.vue')
    },
    meta: {
      requiresEditorRole: true,
    },
  },

  {
    path: '/admin/post/new',
    name: 'NewPost',
    component: () => {
      return import( /* webpackChunkName: "EditPost" */ '@/views/admin/EditPost.vue')
    },
    meta: {
      requiresEditorRole: true,
    },
  },

  {
    path: '/admin/post/edit/:id_articolo',
    name: 'EditPost',
    component: () => {
      return import( /* webpackChunkName: "EditPost" */ '@/views/admin/EditPost.vue')
    },
    meta: {
      requiresEditorRole: true,
    },
  },



  // End of authenticated views
  // ---------------------------

  {
    path: '/',
    name: 'ChiSiamo',
    component: () => {
      return import( /* webpackChunkName: "ChiSiamo" */ '@/views/Home.vue')
    },
  },
  {
    path: '/where',
    name: 'DoveSiamo',
    component: () => {
      return import( /* webpackChunkName: "DoveSiamo" */ '@/views/DoveSiamo.vue')
    },
  },
  {
    path: '/customers',
    name: 'Clienti',
    component: () => {
      return import( /* webpackChunkName: "Clienti" */ '@/views/Clienti.vue')
    },
  },
  {
    path: '/test',
    name: 'TestPage',
    component: () => {
      return import( /* webpackChunkName: "TestPage" */ '@/views/test/TestPage.vue')
    },
  },

  {
    path: '/article/:id_articolo',
    name: 'ReadFullArticle',
    component: () => {
      return import( /* webpackChunkName: "ReadFullArticle" */ '@/views/ReadFullArticle.vue')
    },
  },

  {
    path: '/test/loadArt',
    name: 'LoadArt',
    component: () => {
      return import( /* webpackChunkName: "LoadArt" */ '@/views/test/LoadArt.vue')
    },
  },

  {
    path: '/about',
    name: 'About',
    component: function () {
      return import( /* webpackChunkName: "about" */ '../views/About.vue')
    }
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0,
      behavior: 'smooth',
    };
  }
})


router.beforeEach((to, from, next) => {
  const isLogged = store.getters["auth/get_isLogged"];

  const isLocked = store.getters["auth/get_isLocked"];
  const isAdmin = store.getters["auth/get_isAdmin"];
  const isEditor = store.getters["auth/get_isEditor"];
  const isContactManager = store.getters["auth/get_isContactManager"];

  console.log('.......................');
  console.log('isLogged', isLogged);
  console.log('isLocked', isLocked);
  console.log('isAdmin', isAdmin);
  console.log('isEditor', isEditor);
  console.log('isContactManager', isContactManager);
  console.log('to -->', to.name);
  if (isLocked && to.name != 'Locked') {
    next('/locked')
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isLogged) {
      next()
    } else {
      next('/admin')
    }
  }


  if (to.matched.some((record) => record.meta.requiresAdminRole)) {
    // const isAdmin = store.getters["auth/get_isAdmin"];
    // const isLogged = store.getters["auth/get_isLogged"];
    if (isLogged) {
      if (!isAdmin) {
        next('/401')
      } else {
        next()
      }
    } else {
      next('/admin')
    }
  } else {
    next()
  }
  // 
  if (to.matched.some((record) => record.meta.requiresEditorRole)) {
    // const isEditor = store.getters["auth/get_isEditor"];
    // const isAdmin = store.getters["auth/get_isAdmin"];
    // const isLogged = store.getters["auth/get_isLogged"];
    if (isLogged) {
      if (!isEditor && !isAdmin) {
        next('/401')
      } else {
        next()
      }
    } else {
      next('/admin')
    }
  } else {
    next()
  }
  // 
  if (to.matched.some((record) => record.meta.requiresContactsManagerRole)) {
    // const isContactManager = store.getters["auth/get_isContactManager"];
    // const isAdmin = store.getters["auth/get_isAdmin"];
    // const isLogged = store.getters["auth/get_isLogged"];
    if (isLogged) {
      if (!isContactManager && !isAdmin) {
        next('/401')
      } else {
        next()
      }
    } else {
      next('/admin')
    }
  } else {
    next()
  }


})


export default router