<template>
    <div>
        <v-btn small v-if="darkmode" icon @click="darkmode =! darkmode">
            <!-- <v-icon small color="secondary">mdi-brightness-7</v-icon> -->
            <v-icon small color="white">mdi-brightness-7</v-icon>
        </v-btn>
        <v-btn small v-if="!darkmode" icon @click="darkmode =! darkmode">
            <!-- <v-icon small color="secondary">mdi-brightness-4</v-icon> -->
            <v-icon small color="white">mdi-brightness-4</v-icon>
        </v-btn>
        <!-- <v-switch color="black" hide-details v-model="darkmode"/> -->
    </div>
</template>

<script>

export default {
    props: {},
    components: {},
    data: () => ({
        darkmode: false
    }),
    methods: {
        handledarkmode() {
            if (process.browser) {
                if (this.darkmode === true) {
                    this.$vuetify.theme.dark = true
                    localStorage.setItem('DarkMode', true)
                    this.$store.dispatch("styling/act_set_modalita", 'dark');
                } else if (this.darkmode === false) {
                    this.$vuetify.theme.dark = false
                    localStorage.setItem('DarkMode', false)
                    this.$store.dispatch("styling/act_set_modalita", 'light');
                }
            }
        },
    },
    mounted() { },
    created() {
        if (process.browser) {
            if (localStorage.getItem('DarkMode')) {
                const cookieValue = localStorage.getItem('DarkMode') === 'true'
                this.darkmode = cookieValue
            } else {
                this.handledarkmode()
            }
        }
    },
    computed: {},
    watch: {
        darkmode(oldval, newval) {
            this.handledarkmode()
        }
    }
}
</script>

<style>

</style>