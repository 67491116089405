<template>
    <div style="margin-top:5rem">
        <v-footer :color="isDark ? 'black' : 'white'" style="margin-top:30rem; box-shadow: 0px -5px 20px black;"
            min-height=80 padless absolute class="text-center">
            <!-- <v-row no-gutters> -->
            <!-- <v-col class="text-center"> -->
            <!-- <span style="font-size:0.8rem">
                        <p>
                            Consorzio 1980 SPA
                        </p>
                    </span> -->
            <div class="text-center" style="width:100%" >
                <div :class="isSmallMobile ? 'mt-2' : 'my-4'"  class="d-flex justify-center">
                    <v-img :max-width="logo_size" alt="LogoFooter" :src="footer_logo" />
                </div>

                <div class="text-center">
                    <v-btn style="height: 5px" class="py-0 my -0" @click="openCoookie()" :style="textColor" small text>
                        <span class="cookie-font">Cookie policy</span>
                    </v-btn>

                </div>
                <div class="text-center">
                    <a href="https://avmsquare.com" target="blank">
                        <span style="font-size:0.8rem" :style="textColor">
                            Made with 💙 by AVMSquare
                        </span>
                    </a>
                </div>
            </div>
            <!-- </v-col> -->
            <!-- </v-row> -->
        </v-footer>
    </div>
</template>

<script>

import AOS from "aos";
import "aos/dist/aos.css";
import { EventBus } from "@/main";

AOS.init()

export default {
    props: {},
    components: {},
    data: () => ({

    }),
    methods: {
        openCoookie() {
            EventBus.$emit("open_cookie_setting");
        },

    },
    mounted() { },
    created() { },
    computed: {
        textColor() {
            if (this.isDark) {
                return 'color: #ffffff !important'
            } else {
                return 'color: #0e0e0e !important'
            }
        },
        footer_logo() {
            if (this.isDark) {
                return require('../assets/logo/light300.png')
            } else {
                return require('../assets/logo/dark300.png')
            }
        },
        logo_size() {
            if (this.isSmallMobile) {
                return '80px'
            } else {
                return '200px'
            }
        },
    },
    watch: {}
}
</script>

<style>

</style>