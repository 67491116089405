const state = {
    contatti: []
}

const getters = {
    get_contatti(state) {
        return state.contatti
    },
}

const mutations = {
    set_contact_state(state, id_state) {
        const contact_index = state.contatti.map(e => e.id).indexOf(id_state.id);
        state.contatti[contact_index].stato = id_state.state
        state.contatti = [...state.contatti]
    },
    set_contact_delete(state, id) {
        const contact_index = state.contatti.map(e => e.id).indexOf(id);
        state.contatti[contact_index].eliminato = true
        state.contatti.splice(contact_index, 1)
        state.contatti = [...state.contatti]
    }
}


const actions = {

    act_write_new_contact({
        state,
        commit,
        dispatch
    }, contact_info) {
        // firestore.collection('articoli').doc(plant_name).set(sample_payload, { merge: true }).then(snapshot => {
        firestore.collection('contatti').doc().set(contact_info, {
            merge: true
        }).then(snapshot => {
            console.log('Write contact_info firestore success');
            // resolve()
        }).catch(error => {
            console.log('Write contact_info firestore error');
            console.log(error);
            // reject(error)
        })
    },
    act_get_contatti({
        state,
        commit,
        dispatch
    }) {
        console.log('act_get_contatti');
        let contacts = []
        return firestore.collection('contatti').where("delete", "!=", true).get().then(snapshot => {
            snapshot.forEach((doc) => {
                let doc_data = doc.data()
                doc_data.id = doc.id
                if (doc_data.eliminato != true) {
                    contacts.push(doc_data)
                }
            });
            state.contatti = contacts
            return contacts;
        }).catch(error => {
            console.log('401 ----------');
            console.log(error);
            router.push({
                name: 'Unauthorized'
            })
        })
    },
    act_change_contact_state({
        state,
        commit,
        dispatch
    }, id_state) {
        const id = id_state.id
        const new_state = id_state.state
        return firestore.collection('contatti').doc(id).set({
            stato: new_state
        }, {
            merge: true
        }).then(() => {
            commit('set_contact_state', id_state)
        })
    },
    act_delete_contact({
        state,
        commit,
        dispatch
    }, id) {
        console.log('act_delete_contact');
        return firestore.collection('contatti').doc(id).set({
            delete: true
        }, {
            merge: true
        }).then(() => {
            commit('set_contact_delete', id)
        })
    },
}



import {
    firestore
} from "@/plugins/firebase"
import router from '@/router'



export const contacts = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}