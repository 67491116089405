<template>
    <div style="width:100%" class="text-center">
        <!-- <h1>Counter dipendenti</h1> -->

        <Bar :chart-options="chartOptions" :chart-data="chartData" chart-id="dipendenti" dataset-id-key="label"
            :width="300" :height="100" />

        <!-- <v-sparkline show-labels :labels="labels" :value="value" color="secondary" width="200" line-width="1"
            stroke-linecap="round">
            <template v-slot:label="item">
                {{ item.value }}
            </template>
        </v-sparkline> -->

        <!-- {{ value }} -->

        <!-- <table style="max-width:200px">
            <tr v-for="(data,index) in show_data" :key="index">
                <td style="width:50%" class="text-center">{{ data.anno}}</td>
                <td style="width:50%" class="text-center">{{ data.count}}</td>
            </tr>
        </table> -->

        <!-- <div >
            {{ data }}
        </div> -->
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

ChartJS.defaults.font.size = 16;
ChartJS.defaults.font.weight = 'bold';


export default {
    props: {},
    components: {
        Bar
    },
    data: () => ({
        chartData: {
            labels: [],
            datasets: [
                {
                    data: [],
                    backgroundColor: ["#ccdded", "#b8d8f5", "#8cc0ed", "#66aeed", "#46a2f2", "#1f8ff2"],
                    // base: ["#ccdded", "#b8d8f5", "#8cc0ed", "#66aeed", "#46a2f2", "#1f8ff2"]
                }],


        },
        chartOptions: {
            responsive: true,

            scales: {
                // y: {
                //     max: 0,
                //     min: 300,
                //     text: "wer",

                //     ticks: {
                //         color: "#1f8ff2",

                //     },
                // },
                yAxes: {
                    display: false,
                    stacked: true,
                    max: 0,
                    min: 300,
                    ticks: {
                        color: "#1f8ff2",

                    },
                },
                xAxes: {
                    ticks: {
                        color: "#1f8ff2",
                    },
                }
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false
                },
            },

        },

        dipendenti: [
            {
                anno: 2017,
                count: 2
            },
            {
                anno: 2018,
                count: 60
            },
            {
                anno: 2019,
                count: 115
            },
            {
                anno: 2020,
                count: 150
            },
            {
                anno: 2021,
                count: 190
            },
            {
                anno: 2022,
                count: 260
            },
        ],
        show_data: [],
        timer: null,
        index: 0
    }),
    methods: {
        show() {
            this.timer = setInterval(() => {
                if (this.index >= this.dipendenti.length) {
                    clearInterval(this.timer)
                    console.log('stop');
                } else {

                    // this.show_data.push(this.dipendenti[this.index])
                    // this.value.push(this.dipendenti[this.index].count)
                    this.chartData.labels.push(this.dipendenti[this.index].anno)
                    this.chartData.datasets[0].data.push(this.dipendenti[this.index].count)

                    this.index += 1
                }

            }, 250);
        }

    },
    mounted() {
        this.show()
    },
    created() { },
    computed: {},
    watch: {}
}
</script>

<style>

</style>