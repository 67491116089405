import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"no-click-animation":"","overlay-opacity":0.8,"overlay":true,"transition":"dialog-transition","max-width":"600","persistent":""},model:{value:(_vm.cookie_policy_dialog),callback:function ($$v) {_vm.cookie_policy_dialog=$$v},expression:"cookie_policy_dialog"}},[_c(VCard,[_c(VCardText,{staticClass:"py-8"},[_c('h2',[_vm._v(_vm._s(_vm.$t('cookiePolicy.h2')))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('cookiePolicy.text1'))+" ")]),_c('h3',[_vm._v(_vm._s(_vm.$t('cookiePolicy.h3.1')))]),_vm._v(" "+_vm._s(_vm.$t('cookiePolicy.text2'))+" "),_c('li',[_vm._v(" Google Analytics: "),_c('a',{attrs:{"target":"_blank","href":"https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"}},[_vm._v("(Link)")])]),_c('li',[_vm._v(" Linkedin: "),_c('a',{attrs:{"target":"_blank","href":"https://it.linkedin.com/legal/l/cookie-table?"}},[_vm._v("(Link)")])]),_c('div',[_c('h3',{staticClass:"mt-8"},[_vm._v(" "+_vm._s(_vm.$t('cookiePolicy.h3.2'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('cookiePolicy.text3'))+" "),_c('li',[_vm._v(" Chrome "),_c('a',{attrs:{"href":"https://support.google.com/chrome/answer/95647?hl=it Firefox:\n          https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie"}},[_vm._v("(Link)")])]),_c('li',[_vm._v(" Internet Explorer "),_c('a',{attrs:{"href":"http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-10\n          Opera: http://help.opera.com/Windows/10.00/it/cookies.html"}},[_vm._v("(Link)")])]),_c('li',[_vm._v(" Safari "),_c('a',{attrs:{"href":"https://www.apple.com/legal/privacy/it/cookies/"}},[_vm._v("(Link)")])])])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","width":"150"},on:{"click":function($event){_vm.cookie_policy_dialog = false}}},[_vm._v("Chiudi")]),_c(VSpacer)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }