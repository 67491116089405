export const computed_locale = {
    computed: {
        current_locale() {
            let locale = this.$store.getters["language/get_language"]
            // console.log(locale);
            locale == null ? locale = 'it': null
            // console.log(locale);
            return locale
        },
    },
}