<template>
    <div>

        <svg v-if="firtsBox" width="100%" height="100%" id="svg" viewBox="0 0 1440 500"
            xmlns="http://www.w3.org/2000/svg" style="display:block;">
            <path
                d="M 0,500 C 0,500 0,250 0,250 C 116,244.78468899521533 232,239.56937799043064 318,235 C 404,230.43062200956936 460,226.50717703349278 544,248 C 628,269.4928229665072 740.0000000000001,316.4019138755981 834,317 C 927.9999999999999,317.5980861244019 1004,271.88516746411483 1102,253 C 1200,234.11483253588517 1320,242.05741626794259 1440,250 C 1440,250 1440,500 1440,500 Z"
                stroke="none" stroke-width="0" :fill="cardColor" fill-opacity="1"
                class="transition-all duration-300 ease-in-out delay-150 path-0"></path>
        </svg>

        <v-card flat tile dark style="" :color="cardColor">
            <svg v-if="!firtsBox" width="100%" height="100%" id="svg" viewBox="0 0 1440 500"
                xmlns="http://www.w3.org/2000/svg" style="display:block">
                <path
                    d="M 0,600 C 0,600 0,300 0,300 C 67.14832535885165,353.5023923444976 134.2966507177033,407.00478468899524 242,382 C 349.7033492822967,356.99521531100476 497.9617224880384,253.48325358851673 613,214 C 728.0382775119616,174.51674641148327 809.8564593301435,199.06220095693783 891,238 C 972.1435406698565,276.93779904306217 1052.6124401913876,330.2679425837321 1144,343 C 1235.3875598086124,355.7320574162679 1337.6937799043062,327.86602870813397 1440,300 C 1440,300 1440,600 1440,600 Z"
                    stroke="none" stroke-width="0" :fill="topWaveColor" fill-opacity="1"
                    transform="rotate(-180 720 300)">
                </path>
            </svg>

            <div class="text-center pt-4 pb-8" :style="textColor">

                <div class="d-flex justify-center">
                    <v-img data-aos="flip-down" data-aos-anchor-placement="bottom-bottom" data-aos-offset="350" class=""
                        :max-width="image_size" alt="Valori" src="@/assets/imgs/values_02.png" />
                </div>
                <h2 data-aos="fade-right" data-aos-anchor-placement="top-bottom" data-aos-offset="350"
                    :style="title_size">
                    {{ $t('home.ourvalues.title') }}
                </h2>

                <h3 data-aos="fade-left" data-aos-anchor-placement="top-bottom" data-aos-offset="450"
                    class=" pb-4 box-subtitle underlined" style="max-width:90vw;">
                    {{ $t('home.ourvalues.subtitle') }}
                </h3>


                <div data-aos="fade-in" data-aos-anchor-placement="bottom-bottom"
                    class="d-flex flex-column justify-center align-center px-6 ">

                    <p class="limit-p-width">
                        {{ $t('home.ourvalues.desc_1') }}
                    </p>

                    <ul>
                        <li></li>
                    </ul>

                    <p class="mb-0 limit-p-width sub-text">
                        {{ $t('home.ourvalues.sub01.title') }}
                    </p>

                    <p class=" limit-p-width desc-text">
                        {{ $t('home.ourvalues.sub01.desc') }}
                    </p>
                    <ul>
                        <li></li>
                    </ul>


                    <p class="mb-0 limit-p-width sub-text">
                        {{ $t('home.ourvalues.sub02.title') }}
                    </p>

                    <p class=" limit-p-width desc-text">
                        {{ $t('home.ourvalues.sub02.desc') }}
                    </p>

                    <ul>
                        <li></li>
                    </ul>

                    <p class="mb-0 limit-p-width sub-text">
                        {{ $t('home.ourvalues.sub03.title') }}
                    </p>

                    <p class=" limit-p-width desc-text">
                        {{ $t('home.ourvalues.sub03.desc') }}
                    </p>
                    <ul>
                        <li></li>
                    </ul>

                    <p class="mb-0 limit-p-width sub-text">
                        {{ $t('home.ourvalues.sub04.title') }}
                    </p>

                    <p class=" limit-p-width desc-text">
                        {{ $t('home.ourvalues.sub04.desc') }}
                    </p>
                    <ul>
                        <li></li>
                    </ul>

                    <p class="mb-0 limit-p-width sub-text">
                        {{ $t('home.ourvalues.sub05.title') }}
                    </p>

                    <p class=" limit-p-width desc-text">
                        {{ $t('home.ourvalues.sub05.desc') }}
                    </p>
                    <ul>
                        <li></li>
                    </ul>

                    <p class="mb-0 limit-p-width sub-text">
                        {{ $t('home.ourvalues.sub06.title') }}
                    </p>

                    <p class=" limit-p-width desc-text">
                        {{ $t('home.ourvalues.sub06.desc') }}
                    </p>
                    <br>
                    <p class="mb-0 limit-p-width">
                        {{ $t('home.ourvalues.closing01') }}
                    </p>

                    <p class="mb-0 limit-p-width">
                        {{ $t('home.ourvalues.closing02') }}
                    </p>

                </div>
            </div>


            <svg v-if="!lastBox" width="100%" height="100%" id="svg" viewBox="0 0 1440 600"
                xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"
                style="display:block">
                <path
                    d="M 0,600 C 0,600 0,300 0,300 C 73.41626794258374,310.1531100478469 146.8325358851675,320.3062200956938 241,338 C 335.1674641148325,355.6937799043062 450.0861244019138,380.9282296650718 554,355 C 657.9138755980862,329.0717703349282 750.8229665071772,251.98086124401914 862,243 C 973.1770334928228,234.01913875598086 1102.622009569378,293.14832535885165 1202,314 C 1301.377990430622,334.85167464114835 1370.688995215311,317.42583732057415 1440,300 C 1440,300 1440,600 1440,600 Z"
                    stroke="none" stroke-width="0" :fill="bottomWaveColor" fill-opacity="1"></path>
            </svg>


        </v-card>

        <svg v-if="lastBox" width="100%" height="100%" id="svg" viewBox="0 0 1440 400"
            xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150">
            <path
                d="M 0,400 C 0,400 0,200 0,200 C 113.81818181818178,200.48803827751198 227.63636363636357,200.97607655502392 326,200 C 424.36363636363643,199.02392344497608 507.27272727272737,196.58373205741628 599,182 C 690.7272727272726,167.41626794258372 791.2727272727271,140.68899521531102 886,140 C 980.7272727272729,139.31100478468898 1069.6363636363637,164.6602870813397 1161,179 C 1252.3636363636363,193.3397129186603 1346.181818181818,196.66985645933016 1440,200 C 1440,200 1440,400 1440,400 Z"
                stroke="none" stroke-width="0" :fill="cardColor" fill-opacity="1" transform="rotate(-180 720 200)">
            </path>
        </svg>

    </div>
</template>

<script>
export default {
    props: {
        topWaveColor: {
            type: String,
            default: 'green'
        },
        bottomWaveColor: {
            type: String,
            default: 'purple'
        },
        boxStyle: {
            type: String,
            default: '1'
        },
        firtsBox: {
            type: Boolean,
            default: false
        },
        lastBox: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    data: () => ({

    }),
    methods: {},
    mounted() { },
    created() { },
    computed: {
        cardColor() {
            if (this.boxStyle == '1') {
                return this.card_positive
            } else {
                return this.card_negative
            }
        },
        textColor() {
            if (this.isDark) {
                if (this.boxStyle == '1') {
                    return 'color: #ffffff'
                } else {
                    return 'color: #0e0e0e'
                }
            } else {
                if (this.boxStyle == '1') {
                    return 'color: #0e0e0e'
                } else {
                    return 'color: #ffffff'
                }
            }
        },
        image_size() {
            if (this.isSmallMobile) {
                return 200
            } else {
                return 300
            }
        },
        title_size() {
            if (this.isSmallMobile) {
                return 'font-size: 2.4rem'
            } else {
                return 'font-size: 3.2rem'
            }
        }
    },
    watch: {}
}
</script>

<style scoped>
.sub-text {
    font-size: 1.2rem;
}

.desc-text {
    font-weight: 400;
}

.box-subtitle {
    font-size: 1.4rem;
}

.underlined {
    text-decoration: underline;
}

.limit-p-width {
    max-width: 540px;
}
</style>