import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from "vuex-persistedstate";


import {
  styling
} from './styling'

import {
  articoli
} from './articoli'

import {
  language
} from './language'

import {
  contacts
} from './contacts'

import {
  auth
} from './auth'

import {
  accounts
} from './accounts'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    styling,
    articoli,
    language,
    contacts,
    auth,
    accounts
  },
  plugins: [createPersistedState({
    paths: [
      "styling.modalita",
      "language.language",
      // "articoli.articoli",
      "articoli.last_read_ts",
      "auth"
    ]
  })]
})