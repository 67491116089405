import Vue from 'vue'
import VueI18n from 'vue-i18n'
import it from '@/assets/translations/it.json';
import en from '@/assets/translations/en.json';

Vue.use(VueI18n)


export default new VueI18n({
    // locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    locale: 'it',
    fallbackLocale: 'en',
    messages: {
        'en': en,
        'it': it
    }
})