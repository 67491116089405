const state = {
    articoli: [],
    more_available: null,
    // Prevent hights read from firestore
    last_read_ts: null,
    min_read_sec: 30
    // 
}

const getters = {
    get_articoli(state) {
        return state.articoli
    },
    get_more_available_articoli(state) {
        return state.more_available
    },
}

const mutations = {
    set_articoli(state, articoli) {
        state.articoli = articoli
    },

    set_clear_articoli(state) {
        state.articoli = []
    },

    append_articolo(state, articolo) {
        state.articoli.push(articolo)
    },

    update_articolo(state, payload) {
        console.log('update_articolo', payload.id);
        const index = state.articoli.map(e => e.id).indexOf(payload.id);
        state.articoli[index] = payload.data
        state.articoli[index].id = payload.id
        // state.articoli
    }
}

function toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

const actions = {

    act_clear_articoli({
        state,
        commit,
        dispatch
    }, articoli) {
        commit("set_clear_articoli");
        // dispatch('plants/act_firestore_get_plant_data', selected_plant, { root: true })
    },

    act_set_articoli({
        state,
        commit,
        dispatch
    }, articoli) {
        commit("set_articoli", articoli);
        // dispatch('plants/act_firestore_get_plant_data', selected_plant, { root: true })
    },

    act_test_write_articolo({
        state,
        commit,
        dispatch
    }, sample_payload) {
        // firestore.collection('articoli').doc(plant_name).set(sample_payload, { merge: true }).then(snapshot => {
        firestore.collection('articoli').doc().set(sample_payload, {
            merge: true
        }).then(snapshot => {
            console.log('Write firestore success');
            // resolve()
        }).catch(error => {
            console.log('Write firestore error');
            console.log(error);
            // reject(error)
        })
    },

    act_get_article_batch_from_latest({
        state,
        commit,
        dispatch
    }, batch_size) {

        let BATCH_SIZE = 4
        if (batch_size) BATCH_SIZE = batch_size
        // console.log(BATCH_SIZE);

        const last_doc = state.articoli.at(-1)
        let last_date = null
        if (last_doc) {
            last_date = last_doc.creation
        }
        const current_timestamp = Math.round(new Date().getTime() / 1000)

        // Variabile contatore articoli letti
        // Ogni get articoli prova a ottenerne N + 1 per decretare se esistono ulteriori documenti
        // a seguire ma vengono effettivamente salvati gli N e scartato il +1. 
        let batch_counter = 0
        let p = []
        // Se non c'è nessun documento da cui inziare a fare offset per il get
        // ottengo i primi per data decrescente 
        if (last_date) {
            firestore.collection('articoli').where("stato", "==", 0).where("creation", "<=", current_timestamp).orderBy("creation", "desc").startAfter(last_date).limit(BATCH_SIZE).get().then(snapshot => {
                snapshot.forEach((doc) => {
                    batch_counter++;
                    if (batch_counter <= BATCH_SIZE - 1) {
                        let doc_data = doc.data()
                        doc_data.id = doc.id
                        commit('append_articolo', doc_data)

                    }
                });
                if (batch_counter == BATCH_SIZE) {
                    state.more_available = true
                } else {
                    state.more_available = false
                }
            })
        } else {
            firestore.collection('articoli').where("stato", "==", 0).where("creation", "<=", current_timestamp).orderBy("creation", "desc").limit(BATCH_SIZE).get().then(snapshot => {
                snapshot.forEach((doc) => {
                    batch_counter++;
                    if (batch_counter <= BATCH_SIZE - 1) {
                        let doc_data = doc.data()
                        doc_data.id = doc.id
                        commit('append_articolo', doc_data)

                    }
                    if (batch_counter > BATCH_SIZE - 1) {
                        state.more_available = true
                    }
                });
                if (batch_counter == BATCH_SIZE) {
                    state.more_available = true
                } else {
                    state.more_available = false
                }
            })
        }
    },



    act_get_article_batch_from_latest_include_draft({
        state,
        commit,
        dispatch
    }, batch_size) {
        let BATCH_SIZE = 4
        if (batch_size) BATCH_SIZE = batch_size
        console.log('act_get_article_batch_from_latest_include_draft');

        const last_doc = state.articoli.at(-1)
        let last_date = null
        if (last_doc) {
            last_date = last_doc.creation
        }
        console.log('last_date', last_date);
        // Variabile contatore articoli letti
        // Ogni get articoli prova a ottenerne N + 1 per decretare se esistono ulteriori documenti
        // a seguire ma vengono effettivamente salvati gli N e scartato il +1. 
        let batch_counter = 0
        let p = []
        // Se non c'è nessun documento da cui inziare a fare offset per il get
        // ottengo i primi per data decrescente 
        if (last_date) {
            firestore.collection('articoli').orderBy("creation", "desc").startAfter(last_date).limit(BATCH_SIZE).get().then(snapshot => {
                snapshot.forEach((doc) => {
                    batch_counter++;
                    if (batch_counter <= BATCH_SIZE - 1) {
                        let doc_data = doc.data()
                        doc_data.id = doc.id
                        commit('append_articolo', doc_data)
                    }
                });
                if (batch_counter == BATCH_SIZE) {
                    state.more_available = true
                } else {
                    state.more_available = false
                }
            })
        } else {
            console.log('batchSize', BATCH_SIZE);
            firestore.collection('articoli').orderBy("creation", "desc").limit(BATCH_SIZE).get().then(snapshot => {
                snapshot.forEach((doc) => {
                    console.log(doc);
                    batch_counter++;
                    if (batch_counter <= BATCH_SIZE - 1) {
                        let doc_data = doc.data()
                        doc_data.id = doc.id
                        console.log('append');
                        commit('append_articolo', doc_data)

                    }
                    if (batch_counter > BATCH_SIZE - 1) {
                        state.more_available = true
                    }
                });
                if (batch_counter == BATCH_SIZE) {
                    state.more_available = true
                } else {
                    state.more_available = false
                }
            })
        }
    },

    act_get_articolo({
        state,
        commit,
        dispatch
    }, id_articolo) {
        console.log('act_get_articolo', id_articolo);
        let p = []
        return firestore.collection('articoli').doc(id_articolo).get().then(doc => {
            let doc_data = doc.data()
            doc_data.id = doc.id
            return doc_data;
        })
    },

    act_post_articolo({
        state,
        commit,
        dispatch
    }, payload) {
        console.log('act_post_articolo');
        console.log(payload);
        const id_articolo = payload.id
        const articolo = Object.assign({}, payload.data)
        console.log(articolo);
        console.log(id_articolo);

        if (id_articolo) {
            console.log(1);
            return firestore.collection('articoli').doc(id_articolo).set(articolo, {
                merge: true
            }).then(doc => {
                console.log('1a');
                commit('update_articolo', payload)
                console.log('modifica articolo salvato');
            }).catch((error) => {
                console.log('1b');
                console.log(error);
            })
        } else {
            console.log(2);
            return firestore.collection('articoli').doc().set(articolo, {
                merge: true
            }).then(doc => {
                console.log('nuovo articolo salvato');
            }).catch((error) => {
                console.log(error);
            })
        }

    },

    act_delete_img_articolo({
        state,
        commit,
        dispatch
    }, payload) {
        console.log('act_delete_img_articolo', payload);
        const fileRef = storage.refFromURL(payload.url);
        console.log(fileRef);

        // Delete the file using the delete() method 
        return fileRef.delete().then(function () {
            // File deleted successfully
            console.log("File Deleted")
            return
        }).catch(function (error) {
            // Some Error occurred
        });

        // // firestore.collection('articoli').doc(plant_name).set(sample_payload, { merge: true }).then(snapshot => {
        // firestore.collection('articoli').doc().set(sample_payload, {
        //     merge: true
        // }).then(snapshot => {
        //     console.log('Write firestore success');
        //     // resolve()
        // }).catch(error => {
        //     console.log('Write firestore error');
        //     console.log(error);
        //     // reject(error)
        // })
    },


}



import {
    firestore,
    storage
} from "@/plugins/firebase"


export const articoli = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}