import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
    apiKey: "AIzaSyA-YHrjhzYeSg3O4RqmT7JUTmuHSFw18ro",
    authDomain: "consorzio1980-web.firebaseapp.com",
    projectId: "consorzio1980-web",
    storageBucket: "consorzio1980-web.appspot.com",
    messagingSenderId: "916463253240",
    appId: "1:916463253240:web:8a2d160bcdf517d9f8ad0d",
    // measurementId: "G-0G1YXYGF0G"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);


// firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
//   .then(() => {
//     // Existing and future Auth states are now persisted in the current
//     // session only. Closing the window would clear any existing state even
//     // if a user forgets to sign out.
//     // ...
//     // New sign-in will be persisted with session persistence.
//     return firebase.auth().signInWithEmailAndPassword(email, password);
//   })
//   .catch((error) => {
//     // Handle Errors here.
//     var errorCode = error.code;
//     var errorMessage = error.message;
//   });


// Initialize Cloud Firestore and get a reference to the service
export const firestore = firebase.firestore();
export const firebaseAuth = firebase.auth()
export const storage = firebase.storage();
