const state = {
    username: null,
    isLocked: false,
    isAdmin: false,
    isEditor: false,
    isLogged: false,
    isContactManager: false,
}

const getters = {
    get_isLogged(state) {
        return state.isLogged
    },
    get_isLocked(state) {
        return state.isLocked
    },

    get_username(state) {
        return state.username
    },
    get_isAdmin(state) {
        return state.isAdmin
    },
    get_isEditor(state) {
        return state.isEditor
    },
    get_isContactManager(state) {
        return state.isContactManager
    }
}

const mutations = {

    set_login(state, {
        username,
        isLocked,
        isAdmin,
        isEditor,
        isContactManager
    }) {
        state.isLocked = isLocked
        state.isAdmin = isAdmin
        state.isEditor = isEditor
        state.isContactManager = isContactManager
        state.username = username
        state.isLogged = true
    },

    set_logout(state) {
        console.log('Logout user');
        state.isLogged = false
        state.isLocked = false
        state.isAdmin = false
        state.isEditor = false
        state.isContactManager = false
        state.username = null
    }

}

const actions = {

    act_logout({
        commit
    }) {
        console.log('act_logout');
        commit("set_logout");
    },

    act_login({
        state,
        commit,
        dispatch
    }, {
        username,
        password
    }) {
        // console.log(username, password);
        this.login_disable = true;
        return firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then(() => {
                firebaseAuth.signInWithEmailAndPassword(username, password)
                    .then((login) => {
                        console.log('Login success');
                        console.log(login);
                        const uid = firebaseAuth.currentUser.uid;
                        console.log(uid);
                        localStorage.setItem("last_login_username", username);
                        // Get user property from collection 'utenti'
                        // firestore.collection('utenti').doc(username).get().then(doc => {
                        firestore.collection('utenti').doc(username).onSnapshot(doc => {
                            console.log(1);
                            if (!doc.exists) {
                                // console.log('Get user property failed, create default');
                                const default_user_payload = {
                                    isLocked: false,
                                    isAdmin: false,
                                    isEditor: false,
                                    isContactManager: true,
                                }
                                console.log('1a');
                                // firestore.collection('utenti').doc(uid).set(default_user_payload).then(() => {
                                firestore.collection('utenti').doc(username).set(default_user_payload).then(() => {
                                    console.log('create default user document');
                                })
                            } else {
                                console.log(2);
                                const doc_data = doc.data()
                                // console.log(doc_data);
                                commit("set_login", {
                                    username: username,
                                    isLocked: doc_data.isLocked,
                                    isAdmin: doc_data.isAdmin,
                                    isEditor: doc_data.isEditor,
                                    isContactManager: doc_data.isContactManager
                                })

                                if (doc_data.isLocked) {
                                    router.push({
                                        name: "Locked"
                                    });
                                }
                            }
                            console.log(doc.data());
                        })
                        // .catch((error) => {
                        //     console.log(3);
                        //     console.error(error);
                        // })
                        // this.$store.dispatch("auth/act_loginInfo", login);
                        // this.$router.push({ name: "Home" });
                        // this.login_disable = false;
                    })
                    .catch((error) => {
                        alert(i18n.t('login.error.badusernamepassword') + '\n\n' + error)
                        console.log('Login failed');
                        // EventBus.$emit("dialogBadSignIn", error);
                        // console.log(error);
                        this.login_disable = false;
                        return error
                    });
            })
        // commit("set_language", language);
    },

    act_pwd_reset({
        state,
        commit,
        dispatch
    }, email) {
        return firebaseAuth.sendPasswordResetEmail(email)
            .then(() => {
                return false
                // Password reset email sent!
                // ..
            })
            .catch((error) => {
                return error
                var errorCode = error.code;
                var errorMessage = error.message;
                // ..
            });

    },


    act_pwd_change({
            state,
            commit,
            dispatch
        },
        newPassword) {
        console.log('act_pwd_change', newPassword);
        const user = firebaseAuth.currentUser;
        return user.updatePassword(newPassword).then(() => {
            return false
            // Update successful.
        }).catch((error) => {
            console.error(error);
            alert(i18n.t('passwordchange.error.badpassword') + '\n\n' + error)
            // An error ocurred
            // ...
        });
    }
}

import {
    firebaseAuth,
    firestore
} from "@/plugins/firebase";
import firebase from 'firebase/compat/app';

import i18n from "@/plugins/i18n";
import router from '@/router/index'


export const auth = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}