const state = {
    modalita: null,
}

const getters = {
    get_modalita(state) {
        return state.modalita
    },
}

const mutations = {
    set_modalita(state, modalita) {
        console.log('√set_modalita', modalita);
        // if (modalita == 'dark') {
        //     this.$vuetify.theme.dark = true
        // } else {
        //     this.$vuetify.theme.dark = false
        // }

        state.modalita = modalita
    },
}

const actions = {
    act_set_modalita({
        state,
        commit,
        dispatch
    }, modalita) {
        commit("set_modalita", modalita);
    }
}




export const styling = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}