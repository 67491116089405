<template>
    <div>
        <h2 class="pa-2" :class="fullArticle ? '':'text-truncate'" :style="title_background">
            <span :style="title_size">
                <!-- {{ locale_titolo(articolo) }} -->
                {{ titolo }}
            </span>
        </h2>
    </div>
</template>

<script>
export default {
    props: {
        titolo: String,
        fullArticle: Boolean
    },
    components: {},
    data: () => ({

    }),
    methods: {},
    mounted() { },
    created() { },
    computed: {
        title_size() {
            if (this.fullArticle != true) {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return "font-size: 1rem"
                    case 'sm':
                        return "font-size: 1.2rem"
                    case 'md':
                        return "font-size: 1.1rem"
                    case 'lg':
                        return "font-size: 1rem"
                    case 'xl':
                        return "font-size: 1.1rem"
                    default:
                        return "font-size: 1.4rem"
                        break;
                }
            } else {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return "font-size: 1.2rem"
                    case 'sm':
                        return "font-size: 1.4rem"
                    case 'md':
                        return "font-size: 1.6rem"
                    case 'lg':
                        return "font-size: 1.8rem"
                    case 'xl':
                        return "font-size: 2rem"
                    default:
                        return "font-size: 1.4rem"
                        break;
                }
            }
        }
    },
    watch: {}
}
</script>

<style>

</style>