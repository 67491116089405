import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hide == false && _vm.articoli.length > 0)?_c(VCard,{staticClass:"py-6",staticStyle:{"overflow":"hidden"},style:(_vm.background_style),attrs:{"flat":"","elevation":"elevation","color":_vm.backgroundColorCard}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"11","sm":"11","md":"10","lg":"9","xl":"6"}},[_c(VRow,_vm._l((_vm.articoli),function(articolo,index){return _c(VCol,{key:articolo.id,attrs:{"cols":"12","sm":"6","md":"4"}},[_c(VCard,{attrs:{"to":{ name: 'ReadFullArticle', params: { id_articolo: articolo.id } },"data-aos":"flip-left","data-aos-delay":_vm.aos_delay(index),"color":_vm.backgroundColor}},[_c(VImg,{staticClass:"secondary--text align-end",attrs:{"height":"350","src":_vm.default_image(articolo),"lazy-src":_vm.default_image(articolo)}},[_c('div',{staticClass:"pr-2 text-right",style:(_vm.title_background)},[_c('span',{staticClass:"body-2",style:(_vm.negative_color_style)},[_vm._v(" "+_vm._s(_vm.articolo_data(articolo))+" ")])]),_c('Titolo',{style:(_vm.negative_color_style),attrs:{"titolo":_vm.locale_titolo(articolo)}}),_c('div',{staticClass:"text-center pt-2",style:(_vm.title_background)},[_c(VBtn,{attrs:{"color":_vm.negative_color_class,"block":"","text":"","outlined":"","to":{ name: 'ReadFullArticle', params: { id_articolo: articolo.id } }}},[_c('span',{style:(_vm.negative_color_style)},[_vm._v(" "+_vm._s(_vm.$t('loadarticoli.readfullarticle'))+" ")])])],1)],1)],1)],1)}),1)],1)],1),_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[(_vm.more_available_articoli)?_c('a',{staticClass:"secondary--text",on:{"click":function($event){return _vm.load_more()}}},[_c('strong',{style:(_vm.negative_color_style)},[_vm._v(" "+_vm._s(_vm.$t('loadarticoli.loadmore'))+" ")])]):_vm._e()])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }