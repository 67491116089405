const state = {
    accounts: [],
}

const getters = {
    get_accounts(state) {
        return state.accounts
    },
}

const mutations = {
    set_accounts(state, accounts) {
        state.accounts = accounts
    },
    set_clear_accounts(state) {
        state.accounts = []
    },

    set_account_edit(state, payload) {
        console.log('set_account_edit', payload);
        const index = state.accounts.map(e => e.email).indexOf(payload.email);
        state.accounts[index] = payload
        console.log(payload);
        state.accounts = [...state.accounts]
        // state.accounts[index].email = payload.email
        // state.articoli
    }
    // commit('user_uopdated', payload)

}


const actions = {

    act_clear_accounts({
        state,
        commit,
        dispatch
    }, articoli) {
        commit("set_clear_accounts");
    },


    act_get_all_accounts({
        state,
        commit,
        dispatch
    }) {

        firestore.collection('utenti').get().then(snapshot => {
            let accounts = []
            snapshot.forEach((doc) => {
                if (doc.id != "a.mendolia@avmsquare.com" && doc.id != "alessandro.mendolia@gmail.com") {
                    let payload = doc.data()
                    payload.email = doc.id
                    accounts.push(payload)
                }
            });
            commit('set_accounts', accounts)
        })

    },


    // act_get_articolo({
    //     state,
    //     commit,
    //     dispatch
    // }, id_articolo) {
    //     console.log('act_get_articolo', id_articolo);
    //     let p = []
    //     return firestore.collection('articoli').doc(id_articolo).get().then(doc => {
    //         let doc_data = doc.data()
    //         doc_data.id = doc.id
    //         return doc_data;
    //     })
    // },

    act_post_account_changes({
        state,
        commit,
        dispatch
    }, payload) {
        console.log('act_post_account_changes');
        console.log(payload);
        const account = payload.email
        const new_payload = Object.assign({}, payload)
        delete new_payload.email
        return firestore.collection('utenti').doc(account).set(new_payload, {
            merge: true
        }).then(doc => {
            payload.email = account
            commit('set_account_edit', payload)
        }).catch((error) => {
            console.log(error);
        })
    },

    // act_delete_img_articolo({
    //     state,
    //     commit,
    //     dispatch
    // }, payload) {
    //     console.log('act_delete_img_articolo', payload);
    //     const fileRef = storage.refFromURL(payload.url);
    //     console.log(fileRef);

    //     // Delete the file using the delete() method 
    //     return fileRef.delete().then(function () {
    //         // File deleted successfully
    //         console.log("File Deleted")
    //         return
    //     }).catch(function (error) {
    //         // Some Error occurred
    //     });

    //     // // firestore.collection('articoli').doc(plant_name).set(sample_payload, { merge: true }).then(snapshot => {
    //     // firestore.collection('articoli').doc().set(sample_payload, {
    //     //     merge: true
    //     // }).then(snapshot => {
    //     //     console.log('Write firestore success');
    //     //     // resolve()
    //     // }).catch(error => {
    //     //     console.log('Write firestore error');
    //     //     console.log(error);
    //     //     // reject(error)
    //     // })
    // },


}



import {
    firestore,
    storage
} from "@/plugins/firebase"


export const accounts = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}