import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAppBar,{style:(_vm.appbar_background),attrs:{"app":"","elevation":"0"}},[(_vm.isSmallMobile)?_c(VAppBarNavIcon,{staticClass:"mr-3",attrs:{"dense":"","small":"","color":_vm.isDark ? 'white' : 'black'},on:{"click":function($event){$event.stopPropagation();_vm.drawer.status = !_vm.drawer.status}}}):_vm._e(),_c(VCard,{attrs:{"color":"transparent","ripple":false,"flat":"","tile":"","to":{ name: 'ChiSiamo' }}},[(!_vm.isDark)?_c(VImg,{attrs:{"to":{ name: 'ChiSiamo' },"data-aos":"fade-in","data-aos-delay":"0","max-width":"120","alt":"Logo","src":require("../assets/logo/dark120.png")}}):_vm._e(),(_vm.isDark)?_c(VImg,{attrs:{"to":{ name: 'ChiSiamo' },"data-aos":"fade-in","data-aos-delay":"0","max-width":"120","alt":"Logo","src":require("../assets/logo/light120.png")}}):_vm._e()],1),_c(VSpacer),(!_vm.isSmallMobile)?_c('TopBarNavigationButtons'):_vm._e(),(!_vm.isLogged && _vm.$vuetify.breakpoint.smAndUp)?_c(VBtn,{attrs:{"lass":"mx-1","small":"","color":"white","icon":"","to":{ name: 'Admin' }}},[_c(VIcon,{attrs:{"small":"","color":"white"}},[_vm._v("mdi-key")])],1):_vm._e(),(_vm.isLogged && _vm.$vuetify.breakpoint.smAndUp)?_c(VBtn,{attrs:{"lass":"mx-1","small":"","color":"white","icon":"","to":{ name: 'Admin' }}},[_c(VIcon,{attrs:{"small":"","color":"white"}},[_vm._v("mdi-location-exit")])],1):_vm._e(),_c('div',{staticClass:"mx-1"},[_c('ThemeSwitcher')],1),_c('div',{staticClass:"mx-1"},[_c('LanguageSwitcher')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }