

const state = {
    language: null,
}

const getters = {
    get_language(state) {
        return state.language
    },
}

const mutations = {
    set_language(state, language) {
        state.language = language
    },
}

const actions = {
    act_set_language({ state, commit, dispatch }, language) {
        commit("set_language", language);
    }
}

export const language = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}